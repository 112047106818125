import * as React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import JWTService from '../Services/JWTService';
import UserService from '../Services/UserService';
import { showMessage } from './Toastr/ToastService';

export default function TokenChecker(): null {
  const { formatMessage: f } = useIntl();

  const [isMessageShown, setIsMessageShown] = React.useState<boolean>(false);
  setInterval(async () => {
    const date = new Date();

    const actualTimeStamp = dayjs(date).unix() * 1000;

    if (JWTService.isTokenExpired() || JWTService.isSocketServerTokenExpired()) {
      UserService.autoLogOut();
    }

    if (
      (JWTService.expirationTimeSocketServerToken() !== false ||
        JWTService.expirationTimeJWTtoken() !== false) &&
      (JWTService.isSocketServerTokenExpired() === false || JWTService.isTokenExpired() === false)
    ) {
      const expirationTimeSocketServerToken = dayjs(
        Number(JWTService.expirationTimeSocketServerToken()) * 1000
      );

      const expirationTimeJWTToken = dayjs(Number(JWTService.expirationTimeJWTtoken()) * 1000);

      const expirationSocketServerTokenInMinute = expirationTimeSocketServerToken.diff(
        actualTimeStamp,
        'minute'
      );

      const expirationJWTTokenInMinute = expirationTimeJWTToken.diff(actualTimeStamp, 'minute');

      if (
        (expirationSocketServerTokenInMinute < 15 || expirationJWTTokenInMinute < 15) &&
        !isMessageShown
      ) {
        const socketServerToken = JWTService.getSocketServerToken();
        if (socketServerToken) {
          const newSocketServerToken = await JWTService.refreshSocketServerToken(socketServerToken);
          if (newSocketServerToken) {
            JWTService.storeSocketServerToken(newSocketServerToken);
          }
        } else {
          let time = 15;
          if (expirationSocketServerTokenInMinute < 15) {
            time = expirationSocketServerTokenInMinute;
          } else if (expirationJWTTokenInMinute < 15) {
            time = expirationJWTTokenInMinute;
          }
          showMessage(f({ id: 'token.checker.expiration.time' }, { time }), 'warning', '');
          setIsMessageShown(true);
        }
      }
    }
  }, 60000);

  return null;
}
