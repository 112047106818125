import { jwtDecode } from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';
import configJs from '../../config';

export interface ISocketServerJWT {
  exp: number;
  iat: number;
  jti: string;
  meta: any;
  nbf: number;
  scopes: any;
  sub: {
    customer_id: number;
    id: number;
    username: string;
  };
}

export interface IApplicationJWT {
  iat: number;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
  user: {
    id: number;
    username: string;
    language: string;
    customerId: string;
    salesChannelId: string;
    branchId: string;
    socketServerToken: string;
    appPermissions: any;
    customers: any;
    selectedCustomers: number[];
  };
}

const JWTService = {
  storeToken(token: string): void {
    localStorage.setItem('JWT', token);
  },

  storeSocketServerToken(token: string): void {
    localStorage.setItem('socketServerToken', token);
  },

  getSocketServerToken(): string | null {
    return localStorage.getItem('socketServerToken');
  },

  getToken(): string {
    const jwt = localStorage.getItem('JWT');
    if (jwt) {
      return jwt;
    }
    console.warn('Empty token.');
    return '';
  },

  getDecodedSocketServerToken(): ISocketServerJWT | false {
    const token = this.getSocketServerToken();

    if (!token) {
      console.error('Cannot decode token. Token is empty?');
      return false;
    }

    return jwtDecode(token);
  },

  getDecodedToken(): IApplicationJWT | false {
    const token = this.getToken();

    if (!token) {
      console.error('Cannot decode token. Token is empty?');
      return false;
    }

    return jwtDecode(token);
  },

  isTokenExpired(): boolean {
    const token = this.getDecodedToken();

    if (!token) {
      console.error('Cannot validate token. Token is empty?');
      return true;
    }

    if (Date.now() / 1000 > token.exp) {
      console.info('Application JWT expired.');
      return true;
    }
    return false;
  },

  isSocketServerTokenExpired(): boolean {
    const token = this.getDecodedSocketServerToken();

    if (!token) {
      console.error('Cannot validate token. Token is empty?');
      return true;
    }

    if (Date.now() >= token.exp * 1000) {
      console.info('SocketServer JWT expired.');
      return true;
    }
    return false;
  },

  expirationTimeSocketServerToken(): number | false {
    const token = this.getDecodedSocketServerToken();

    if (!token) {
      console.error('Cannot validate token. Token is empty?');
      return false;
    }

    return token.exp;
  },

  expirationTimeJWTtoken(): number | false {
    const token = this.getDecodedToken();

    if (!token) {
      console.error('Cannot validate token. Token is empty?');
      return false;
    }

    return token.exp;
  },

  async refreshSocketServerToken(token: string): Promise<any> {
    const instance = axios.create();
    return instance
      .post(configJs.Socket.refreshTokenUrl, { token })
      .then((response: AxiosResponse<any>) => {
        if (response.data.token) {
          return response.data.token;
        }
        return false;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default JWTService;
