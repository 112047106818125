import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { IWidget } from '../WidgetService';
import { IGridLayoutDroppingItem } from '../Templates/TemplateForm';
import AlarmWidget from './AlarmWidget';
import AltitudeWidget from './AltitudeWidget';
import BatteryWidget from './BatteryWidget';
import DoorsWidget from './DoorsWidget';
import EcoDriveWidget from './EcoDriveWidget';
import EngineTemperatureWidget from './EngineTemperatureWidget';
import FuelLevelLitersWidget from './FuelLevelLitersWidget';
import FuelLevelPercentageWidget from './FuelLevelPercentageWidget';
import LastPositionWidget from './LastPositionWidget';
import RideTypeWidget from './RideTypeWidget';
import RpmWidget from './RpmWidget';
import SatellitesWidget from './SatellitesWidget';
import SpeedWidget from './SpeedWidget';
import ThrottleWidget from './ThrottleWidget';
import VehicleCardWidget from './VehicleCardWidget';
import WaypointWidget from './WaypointWidget';
import AdditionalEquipmentWidget from './AdditionalEquipmentWidget';
import OdometerWidget from './OdometerWidget';
import CngWidget from './CngWidget';
import LpgWidget from './LpgWidget';
import CruiseOnWidget from './CruiseOnWidget';
import LockedVehicleWidget from './LockedVehicleWidget';
import ElectroBatteryWidget from './ElectroBatteryWidget';
import ElectroRangeWidget from './ElectroRangeWidget';
import ElectroChargingWidget from './ElectroChargingWidget';
import ElectroEngineWidget from './ElectroEngineWidget';
import RideBookWidget from './RideBookWidget';
import BluecoinWidget from './BluecoinWidget';
import TachowebWidget from './TachowebWidget';
import RefuelingOverviewWidget from './RefuelingOverviewWidget';
import LastRideWidget from './LastRideWidget';
import ChargingOverviewWidget from './ChargingOverviewWidget';

export interface IBaseWidgetProps {
  index: number;
  data: IWidget;
  isMockupMode?: boolean; // When isMockupMode is true, mockup data are displayed in widget
  isInGrid?: boolean; // When isInGrid is true, no dragging handlers are possible
}

interface IWidgetProps extends IBaseWidgetProps {
  gridLayout: Layout[]; // @todo Remove unused grid layout
  setDraggedWidget?: React.Dispatch<React.SetStateAction<string | null>>;
  setDroppingWidget?: React.Dispatch<React.SetStateAction<IGridLayoutDroppingItem | undefined>>;
  onRemoveCallback?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface ICustomWidgetProps extends IBaseWidgetProps {
  onDragCallback: (event: React.DragEvent<HTMLDivElement>) => void;
  onRemoveCallback: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const widgets = {
  battery: BatteryWidget,
  speed: SpeedWidget,
  rpm: RpmWidget,
  engine_temperature: EngineTemperatureWidget,
  satellites: SatellitesWidget,
  fuel_level_percentage: FuelLevelPercentageWidget,
  fuel_level_liters: FuelLevelLitersWidget,
  altitude: AltitudeWidget,
  throttle: ThrottleWidget,
  vehicle_card: VehicleCardWidget,
  eco_drive: EcoDriveWidget,
  alarms: AlarmWidget,
  doors: DoorsWidget,
  waypoints: WaypointWidget,
  ride_type: RideTypeWidget,
  last_position: LastPositionWidget,
  additional_equipment: AdditionalEquipmentWidget,
  odometer: OdometerWidget,
  vehicle_on_cng: CngWidget,
  vehicle_on_lpg: LpgWidget,
  cruise_on: CruiseOnWidget,
  remote_lock: LockedVehicleWidget,
  ev_battery: ElectroBatteryWidget,
  ev_range: ElectroRangeWidget,
  ev_charging: ElectroChargingWidget,
  ev_state: ElectroEngineWidget,
  ride_overview: RideBookWidget,
  bluecoins: BluecoinWidget,
  tachoweb: TachowebWidget,
  refueling_overview: RefuelingOverviewWidget,
  last_ride: LastRideWidget,
  charging_overview: ChargingOverviewWidget
};

export default function Widget(props: IWidgetProps) {
  if (!widgets[props.data.type]) {
    console.info(`Widget ${props.data.type} with id ${props.data.id} is not defined.`);
    return null;
  }

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    // This is a hack for Firefox
    // Firefox requires some kind of initialization
    // which we can do by adding this attribute
    // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
    event.dataTransfer.setData('text/plain', '');
    if (props.setDraggedWidget && props.setDroppingWidget) {
      props.setDraggedWidget(props.data.gridName);
      const newDroppingItem: IGridLayoutDroppingItem = {
        // i: `${props.gridLayout.length + 1}-${props.data.gridName}`,
        i: `${props.data.gridName}`,
        w: props.data.columnSize,
        h: props.data.rowSize,
      };
      props.setDroppingWidget(newDroppingItem);
    }
  };

  const Component = widgets[props.data.type];

  return (
    <Component
      index={props.index}
      data={props.data}
      onDragCallback={props.isInGrid ? undefined : handleDrag}
      isMockupMode={props.isMockupMode}
      isInGrid={props.isInGrid}
      onRemoveCallback={props.onRemoveCallback}
    />
  );
}
