/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { convertDuration } from '../../RidePlayer/RidePlayerService';
import { IAnnouncement } from '../../RidePlayer/interfaces';

function AdditionalEquipmentWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<IAnnouncement[]>([]);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const binaryEvents = useRecoilValue<IAnnouncement[]>(OnlinePanelState.binaryEvents(vehicleId));

  const getTooltip = (data: IAnnouncement[]) => {
    let tooltip = '';
    data.forEach((announcement: IAnnouncement) => {
      // const string = `${f({ id: `admin.events.code.${announcement.title}` })} ${
      //   announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
      // }`;
      const string = `${f({ id: `${announcement.title}` })} ${
        announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
      }`;
      tooltip += `${string}\n`;
    });
    if (tooltip.length === 0) {
      tooltip = f({ id: 'widget.additionalEquipment.noActiveAdditionalEquipments' });
    }
    return tooltip;
  };

  React.useMemo(() => {
    if (!props.isMockupMode) {
      setWidgetData(binaryEvents);
    } else {
      setWidgetData([
        {
          id: 0,
          activatedAt: '2022-02-17 08:23:39',
          duration: 978,
          eventType: 'binary',
          title: 'warning_light_on',
          type: 'event',
        },
        {
          id: 0,
          activatedAt: '2022-02-17 08:23:39',
          duration: 90,
          eventType: 'binary',
          title: 'warning_sound_on',
          type: 'event',
        },
      ]);
    }
  }, [binaryEvents, props.isMockupMode]);

  let widgetTooltip = getTooltip(binaryEvents);
  if (props.isInGrid && props.isMockupMode) {
    widgetTooltip = getTooltip(widgetData);
  }
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  return (
    <div
      data-cy="additional-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}
        ${widgetData.length === 0 ? ' b-widget--carInfo' : ''}
        ${
          props.data.rowSize === 1 && props.data.columnSize === 4 && widgetData.length === 0
            ? ' b-widget--carInfo'
            : ''
        }
      `}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="additional-widget-in-grid"
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            left: 'inherit',
            transform: 'inherit',
          }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <>
          {widgetData.length > 0 ? (
            <i
              data-cy="additional-widget-icon-size-1-row-1"
              style={{ left: '15px', fontSize: '40px', color: '#533DA2' }}
              className="e-widget-icon cmd-icon cmd-icon-event-on"
            />
          ) : (
            <i
              data-cy="additional-widget-icon-no-data"
              className="e-widget-icon cmd-icon cmd-icon-event-off"
              style={{ left: '15px', fontSize: '40px', color: '#737373' }}
            />
          )}
        </>
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 1 && (
        <>
          {widgetData.length === 0 && (
            <i
              data-cy="additional-widget-icon-size-4-row-1"
              title={f({ id: 'widget.additionalEquipment.title' })}
              style={widgetData.length > 0 ? {} : { color: '#737373' }}
              className={`e-widget-icon e-widget-icon--md cmd-icon ${'cmd-icon-event-off'}`}
            />
          )}
          {widgetData.length > 0 && (
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {widgetData.length <= 3 &&
                widgetData.map((announcement: IAnnouncement, index: number) => (
                  <li
                    style={{ paddingTop: '5px', color: '#533DA2' }}
                    key={`additional-equipment-${index}`}
                    // title={`${f({ id: announcement.title })} ${
                    //   announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
                    // }`}
                  >
                    <i
                      style={{ fontSize: '13px', paddingRight: '5px', color: '#533DA2' }}
                      className="e-widget-icon cmd-icon cmd-icon-event-on"
                    />
                    <span>
                      {/* {f({ id: `admin.events.code.${announcement.title}` })}{' '} */}
                      {f({ id: `${announcement.title}` })}{' '}
                      {announcement.duration ? `(${convertDuration(announcement.duration)})` : ''}
                    </span>
                  </li>
                ))}
              {widgetData.length > 3 &&
                widgetData.map(
                  (announcement: IAnnouncement, index: number) =>
                    index < 2 && (
                      <li
                        style={{ paddingTop: '5px', color: '#533DA2' }}
                        key={`additional-equipment-${index}`}
                        // title={`${f({ id: announcement.title })} ${
                        //   announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
                        // }`}
                      >
                        <i
                          style={{ fontSize: '13px', paddingRight: '5px', color: '#533DA2' }}
                          className="e-widget-icon cmd-icon cmd-icon-event-on"
                        />
                        <span>
                          {/* {f({ id: `admin.events.code.${announcement.title}` })}{' '} */}
                          {f({ id: `${announcement.title}` })}{' '}
                          {announcement.duration
                            ? `(${convertDuration(announcement.duration)})`
                            : ''}
                        </span>
                      </li>
                    )
                )}
              {widgetData.length > 3 && (
                <li style={{ paddingTop: '5px', paddingLeft: '17px' }}>
                  +{widgetData.length - 2} {f({ id: 'widget.additionalEquipment.info' })}
                </li>
              )}
            </ul>
          )}
          {widgetData.length === 0 && (
            <>{f({ id: 'widget.additionalEquipment.noActiveAdditionalEquipments' })}</>
          )}
        </>
      )}
    </div>
  );
}

export default AdditionalEquipmentWidget;
