import * as React from 'react';
import { useIntl } from 'react-intl';

const Error500: React.FC = () => {
  const { formatMessage: f } = useIntl();
  return (
    <div className="w-page">
      <div className="w-page-content">
        <div className="page-header">
          <h4 className="page-header__title">500 Error</h4>
        </div>

        <div className="w-error w-error--500 d-flex align-items-center">
          <div className="container-fluid">
            <div className="b-error">
              <div className="p-6">
                <h3 className="b-error-title">{f({ id: 'error500.title' })}</h3>
                <h4 className="b-error-subtitle">{f({ id: 'error500.subtitle' })}</h4>
              </div>

              <div className="b-error-footer">
                <a href="/" className="e-button e-button--gray">
                  {f({ id: 'error500.back' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error500;
