import { AxiosError } from 'axios';
import * as HTTPService from '../../Services/HttpService';

interface IApplicationUpdateApiResponse {
  version: string;
}

/**
 * Load version from generated meta.json file
 * @returns {Promise<string | null>}
 */
export const getVersion = async (): Promise<string | null> => {
  const response = await HTTPService.get<IApplicationUpdateApiResponse | AxiosError | false>(
    'meta.json',
    {
      baseUrl: '/',
      showErrorToaster: false,
    }
  );
  if (response) {
    return (response as IApplicationUpdateApiResponse).version;
  }
  console.warn('Checking for new version failed. Cannot load meta.json.');
  return null;
};

/**
 * Set application version to localStorage
 * @param {string} version
 * @returns {void}
 */
export const setVersion = (version: string): void => {
  localStorage.setItem('APP_VERSION', version);
};

/**
 * Is latest version?
 * @param {string} version
 */
export const isLatestVersion = (version: string | null): boolean => {
  if (version === localStorage.getItem('APP_VERSION')) {
    return true;
  }
  return false;
};
