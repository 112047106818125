import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { IntlShape } from 'react-intl';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import GoogleMapsService from '../../Services/GoogleMapsService';
import {
  IRideMapAlarmPositionMarkerTooltips,
  IRideMapCombinedAlarmMarkers,
} from './RidePlayerAlarmMarkerService';
import ApiService, { RESPONSE_OK } from '../../Services/ApiService';
import * as HttpService from '../../Services/HttpService';
import { showMessage } from '../Toastr/ToastService';
import UserService from '../../Services/UserService';
import {
  ANNOUNCEMENT_TYPE_ALARM,
  ANNOUNCEMENT_TYPE_WAYPOINT,
} from '../OnlinePanel/OnlinePanelState';
import * as RidePlayerMarkerService from './RidePlayerMarkerService';
import { ICheckedTableItemsAndFilter } from '../TripMap';
import {
  IAnnouncement,
  IPlayedLines,
  IRide,
  IRideAlarmLinePaths,
  IRideDetail,
  IRideEcoDriveLinePaths,
  IRideEventLinePaths,
  IRideLines,
  IRideMapAlarmLines,
  IRideMapEcoDriveLines,
  IRideMapEventLines,
  IRideMapMarkerTooltips,
  IRideMapMarkers,
  IRideMapSpeedLimitLines,
  IRideMapWaitingLines,
  IRidePlayerSettings,
  IRidePlayerSpeedLimitsSettings,
  IRidePlayerTimelineAlarmLayer,
  IRidePlayerTimelineEcoDriveLayer,
  IRidePlayerTimelineEventLayer,
  IRidePlayerTimelineSpeedlimitLayer,
  IRidePosition,
  IRidePositionsApiResponseData,
  IRideSpeedLimitLinePaths,
  IRideTimelineLayers,
  IRideWaitingLinePaths,
  RidePlayerSettingsApiResponse,
  TRideActiveLayer,
} from './interfaces';
import {
  ACTIVE_RIDE_COLOR,
  ACTIVE_RIDE_OPACITY,
  ACTIVE_RIDE_SIZE,
  ACTIVE_RIDE_ZINDEX,
  ALARM_ACTIVE_LINE_COLOR,
  ALARM_LINE_OPACITY,
  ALARM_LINE_SIZE,
  ALARM_LINE_ZINDEX,
  DARK_GREY_LINE_COLOR,
  EVENT_ACTIVE_LINE_COLOR,
  EVENT_LINE_OPACITY,
  EVENT_LINE_SIZE,
  EVENT_LINE_ZINDEX,
  IS_CUSTOMER_WAYPOINT,
  IS_EVENT,
  IS_PAUSE,
  IS_PAUSE_POSITION,
  IS_PRIVATE_WAYPOINT,
  IS_RIDE_LAYER,
  RIDE_LINE_COLOR,
  RIDE_LINE_OPACITY,
  RIDE_LINE_SIZE,
  RIDE_LINE_ZINDEX,
  WAITING_LINE_COLOR,
  WAITING_LINE_OPACITY,
  WAITING_LINE_SIZE,
  WAITING_LINE_ZINDEX,
} from './constants';

dayjs.extend(duration);

export const getVehicleLastRide = async (vehicleId: number) => {
  const url = `/v1/vehicle/${vehicleId}/ride/last/player`;
  const response = await HttpService.get(url, { returnAxiosResponse: true });
  if (
    (response as AxiosResponse).data &&
    (response as AxiosResponse).data.status === RESPONSE_OK &&
    (response as AxiosResponse).data.toast
  ) {
    showMessage('toastr.warning', (response as AxiosResponse).data.toast.message, 'warning', null);
    return null;
  }
  if (
    (response as AxiosResponse).data &&
    (response as AxiosResponse).data.status === RESPONSE_OK &&
    !(response as AxiosResponse).data.toast
  ) {
    return (response as AxiosResponse).data.rides;
  }
  showMessage(
    'toast.ridePlayer.cannotLoadRide.title',
    'toast.ridePlayer.cannotLoadRide.message',
    'error'
  );
  return null;
};

/**
 * Get ride positions
 * @param {number[] | string[]} rideIds
 * @param {boolean} isLastRidePlayer
 * @returns {Promise<IRidePositionsApiResponseData | null>}
 */
export const getRidePositions = async (
  rideIds: { [customerId: string]: string[] },
  isLastRidePlayer?: boolean
): Promise<IRidePositionsApiResponseData | null> => {
  let url = '/v2/ride/player/ridebook';
  if (isLastRidePlayer) {
    url = '/v2/ride/player/map';
  }
  const response = await ApiService.post(url, { rideIds }, true);
  if (response.data && response.data.status === RESPONSE_OK && response.data.toast) {
    showMessage('toastr.warning', response.data.toast.message, 'warning', null);
    return null;
  }
  if (
    response.data &&
    response.data.status === RESPONSE_OK &&
    !response.data.toast &&
    response.data.rides &&
    response.data.rides.length > 0
  ) {
    return response.data.rides;
  }
  showMessage(
    'toast.ridePlayer.cannotLoadRide.title',
    'toast.ridePlayer.cannotLoadRide.message',
    'error'
  );
  return null;
};

export const getMultiRidesPositions = async (
  checkedTableItems: ICheckedTableItemsAndFilter
): Promise<IRidePositionsApiResponseData | null> => {
  const input = {
    filter: checkedTableItems.filter,
    idAllowlist:
      Object.keys(checkedTableItems.rideIds).length === 0 ? null : checkedTableItems.rideIds,
    idBlocklist:
      Object.keys(checkedTableItems.rideIdBlacklist).length === 0
        ? null
        : checkedTableItems.rideIdBlacklist,
    tsAllowlist:
      Object.keys(checkedTableItems.intervals).length === 0 ? null : checkedTableItems.intervals,
    tsBlocklist:
      Object.keys(checkedTableItems.intervalsBlacklist).length === 0
        ? null
        : checkedTableItems.intervalsBlacklist,
  };
  const response = await ApiService.post('v2/ride/player/ridebook', input, false, 180000);
  if (response.data && response.data.status === RESPONSE_OK) {
    return response.data.rides;
  }
  showMessage(
    'toast.ridePlayer.cannotLoadRide.title',
    'toast.ridePlayer.cannotLoadRide.message',
    'error'
  );
  return null;
};

/**
 * Load settings
 * @returns {Promise<IRidePlayerSettings | null>}
 */
export const loadSettings = async (): Promise<IRidePlayerSettings | null> => {
  const response = await HttpService.get<RidePlayerSettingsApiResponse | false>(
    '/v1/user-settings/ride_player'
  );
  if (response) {
    return (response as RidePlayerSettingsApiResponse).settings.value;
  }
  return null;
};

/**
 * Save settings
 * @param {'line' | 'line_arrow'} rideRenderType
 * @param {boolean} showPause
 * @param {boolean} enableSpeedlimits
 * @param {IRidePlayerSpeedLimitsSettings} speedLimitsSettings
 * @param {number} playerSpeed
 * @returns {Promise<any>}
 */
export const saveSettings = async (
  rideRenderType: 'line' | 'line_arrow',
  showPause: boolean,
  enableSpeedlimits: boolean,
  speedLimitsSettings: IRidePlayerSpeedLimitsSettings,
  playerSpeed: number
): Promise<any> => {
  const data: IRidePlayerSettings = {
    rideRenderType,
    showPause,
    speedLimits: {
      enabled: enableSpeedlimits,
      limits: [
        speedLimitsSettings.minimumSpeed,
        speedLimitsSettings.warningSpeed,
        speedLimitsSettings.maximumSpeed,
      ],
    },
    playerSpeed,
  };
  const response = await ApiService.post('/v1/user-settings/ride_player/save', data);
  if (
    response &&
    response.data &&
    response.data.settings &&
    response.data.settings.value &&
    response.data.settings.value.speedLimits &&
    response.data.settings.value.speedLimits.limits
  ) {
    localforage.setItem('speedLimits', response.data.settings.value.speedLimits.limits);
  }
  return response ? response.data : null;
};

export const convertDuration = (durationInSeconds: number): string => {
  if (durationInSeconds <= 59) {
    return dayjs.duration(durationInSeconds, 'seconds').format('s[s]');
  }
  if (durationInSeconds >= 60 && durationInSeconds < 3600) {
    return dayjs.duration(durationInSeconds, 'seconds').format('m[m] s[s]');
  }
  return dayjs.duration(durationInSeconds, 'seconds').format('H[h] m[m] s[s]');
};

/**
 * Get eco drive
 * @param {number} ecoDrive
 * @returns {{ key: string; color: string }}
 */
export const getEcoDrive = (ecoDrive: number | null): { key: string; color: string } => {
  let iconColor = '#146755';
  let iconType = 'good';
  if (ecoDrive === null) {
    return {
      key: iconType,
      color: iconColor,
    };
  }
  if (ecoDrive <= 4.9) {
    iconColor = '#b50536';
    iconType = 'bad';
    return {
      key: iconType,
      color: iconColor,
    };
  }
  if (ecoDrive >= 5.0 && ecoDrive <= 7.9) {
    iconColor = '#df731d';
    iconType = 'not-good';
    return {
      key: iconType,
      color: iconColor,
    };
  }
  return {
    key: iconType,
    color: iconColor,
  };
};

/**
 * Get speed limit
 * @param {IRidePlayerSpeedLimitsSettings} speedLimits
 * @param {number} speed
 * @returns {{ key: string; color: string }}
 */
export const getSpeedlimit = (
  speedLimits: IRidePlayerSpeedLimitsSettings,
  speed: number
): { key: string; color: string } => {
  if (speed >= speedLimits.minimumSpeed && speed < speedLimits.warningSpeed) {
    return {
      key: 'minimumSpeed',
      color: '#BF9300',
    };
  }
  if (speed >= speedLimits.warningSpeed && speed < speedLimits.maximumSpeed) {
    return {
      key: 'warningSpeed',
      color: '#B24E13',
    };
  }
  if (speed >= speedLimits.maximumSpeed) {
    return {
      key: 'maximumSpeed',
      color: '#541325',
    };
  }
  return {
    key: 'normalSpeed',
    color: '#146755',
  };
};

/**
 * Get alarms from announcements
 * @param {IAnnouncement[]} announcements
 * @param {boolean} isOnlinePanel
 * @returns {IAnnouncement[]}
 */
export const getAlarmsFromAnnouncements = (
  announcements: IAnnouncement[],
  isOnlinePanel = false
): IAnnouncement[] => {
  const userId = UserService.getUserId();
  if (isOnlinePanel) {
    // handle on map (online panel)
    const alarms = announcements.filter(
      (item: IAnnouncement) =>
        item.type === ANNOUNCEMENT_TYPE_ALARM &&
        item.visibilityMap &&
        userId &&
        item.visibilityMap.includes(userId)
    );
    return alarms;
  }
  const alarms = announcements.filter(
    // handle in player
    (item: IAnnouncement) =>
      item.type === ANNOUNCEMENT_TYPE_ALARM &&
      item.visibilityRide &&
      userId &&
      item.visibilityRide.includes(userId)
  );
  return alarms;
};

/**
 * Get alarms from announcements in position
 * @param {IRidePosition | undefined} position
 * @returns {IAnnouncement[]}
 */
export const getAlarmsFromAnnouncementsInPosition = (
  position: IRidePosition | undefined
): IAnnouncement[] => {
  if (!position) {
    console.warn('Alarms: position is undefined.');
    return [];
  }
  if (!position.announcements) {
    console.warn(`Announcementes are null for position: ${position.id}`);
    return [];
  }

  return getAlarmsFromAnnouncements(position.announcements);
};

/**
 * Get waypoints from announcements
 * @param {IAnnouncement[]} announcements
 * @returns {IAnnouncement[]}
 */
export const getWaypointsFromAnnouncements = (announcements: IAnnouncement[]): IAnnouncement[] => {
  const userId = UserService.getUserId();

  const waypoints = announcements.filter(
    (item: IAnnouncement) =>
      (item.type === ANNOUNCEMENT_TYPE_WAYPOINT &&
        item.scope === IS_PRIVATE_WAYPOINT &&
        item.userId &&
        userId &&
        item.userId === userId) ||
      item.scope === IS_CUSTOMER_WAYPOINT
  );
  return waypoints;
};

/**
 * Get waypoints from announcements in position
 * @param {IRidePosition | undefined } position
 * @returns {IAnnouncement[]}
 */
export const getWaypointsFromAnnouncementsInPosition = (
  position: IRidePosition | undefined
): IAnnouncement[] => {
  if (!position) {
    console.warn('Waypoints: position is undefined.');
    return [];
  }
  if (!position.announcements) {
    console.warn(`Announcementes are null for position: ${position.id}`);
    return [];
  }

  return getWaypointsFromAnnouncements(position.announcements);
};

/**
 * Get events from announcements in position
 * @param {IRidePosition | undefined} position
 * @returns {IAnnouncement[]}
 */
export const getEventsFromAnnouncementsInPosition = (
  position: IRidePosition | undefined
): IAnnouncement[] => {
  if (!position) {
    console.warn('Events: position is undefined.');
    return [];
  }
  if (!position.announcements) {
    console.warn(`Announcementes are null for position: ${position.id}`);
    return [];
  }

  const announcements = position.announcements.filter(
    (item: IAnnouncement) => item.type === IS_EVENT
  );
  if (announcements) {
    return announcements;
  }
  return [];
};

/**
 * Get waitings from announcements in position
 * @param {IRidePosition | undefined} position
 * @returns {IAnnouncement[]}
 */
export const getWaitingsFromAnnouncementsInPosition = (
  position: IRidePosition | undefined
): IAnnouncement | null => {
  if (!position) {
    console.warn('Waitings: position is undefined.');
    return null;
  }
  if (!position.announcements) {
    console.warn(`Announcementes are null for position: ${position.id}`);
    return null;
  }

  const announcements = position.announcements.find(
    (item: IAnnouncement) => item.type === IS_PAUSE
  );
  if (announcements && announcements.duration) {
    return announcements;
  }

  return null;
};

/**
 * Create ride lines
 * @param {google.maps.Map} map
 * @param {IRide} rides
 * @returns {IRideLines}
 */
export const createRideLines = (map: google.maps.Map, rides: IRide[]): IRideLines => {
  const rideLines: IRideLines = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    rideLines[rideNumber] = GoogleMapsService.createPolyline(
      map,
      [],
      RIDE_LINE_COLOR,
      RIDE_LINE_OPACITY,
      RIDE_LINE_SIZE,
      RIDE_LINE_ZINDEX
    );
    const rideLinePath: google.maps.LatLng[] = [];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      rideLinePath.push(
        new google.maps.LatLng(
          ride.positions[positionIndex].gpsLat,
          ride.positions[positionIndex].gpsLon
        )
      );
    }
    rideLines[rideNumber].setPath(rideLinePath);
  }
  return rideLines;
};

/**
 * Add new position to ride line for specified ride
 * @param {IRideLines} rideLines
 * @param {number} rideNumber
 * @param {IRidePosition} newPosition
 */
export const addNewRideLinePositionForRide = (
  rideLines: IRideLines,
  rideNumber: number,
  newPosition: IRidePosition
): void => {
  const pathItems = rideLines[rideNumber].getPath().getArray();
  const lastPosition = pathItems[pathItems.length - 1];
  if (
    lastPosition.toJSON().lat !== newPosition.gpsLat &&
    lastPosition.toJSON().lng !== newPosition.gpsLon
  ) {
    const newPathItems = [
      ...pathItems,
      new google.maps.LatLng(newPosition.gpsLat, newPosition.gpsLon),
    ];
    rideLines[rideNumber].setPath(newPathItems);
  }
};

/**
 * Set ride line opacity
 * @param {IRideLines} rideLines
 * @param {number} rideNumber
 * @param {number} opacity
 * @returns {void}
 */
export const setRideLineOpacity = (
  rideLines: IRideLines,
  rideNumber: number,
  opacity: number
): void => {
  if (rideLines[rideNumber]) {
    rideLines[rideNumber].setOptions({ strokeOpacity: opacity });
  }
};

/**
 * Calculate percentage for ride position
 * @param {number} position
 * @param {number} totalPositions
 * @returns {number}
 */
const calculatePercentage = (position: number, totalPositions: number): number => {
  return (position / totalPositions) * 100;
};

/**
 * Create timeline layers from rides
 * @param {IRide[]} rides
 * @returns {IRideTimelineLayers}
 */
export const createTimelineLayers = (rides: IRide[]): IRideTimelineLayers => {
  const timeline: IRideTimelineLayers = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    timeline[rideNumber] = {
      alarmLayer: {},
      rideLayer: {},
    };
    const ride = rides[rideIndex];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      timeline[rideNumber].rideLayer[positionIndex] = calculatePercentage(
        positionIndex,
        ride.positions.length
      );
      const positionAlarms = getAlarmsFromAnnouncementsInPosition(ride.positions[positionIndex]);
      if (positionAlarms.length > 0) {
        timeline[rideNumber].alarmLayer[positionIndex] = calculatePercentage(
          positionIndex,
          ride.positions.length
        );
      }
    }
  }
  return timeline;
};

/**
 * Create timeline alarm layer
 * @param {IRide[]} rides
 * @returns {IRidePlayerTimelineAlarmLayer}
 */
export const createTimelineAlarmLayer = (rides: IRide[]): IRidePlayerTimelineAlarmLayer => {
  const layer: IRidePlayerTimelineAlarmLayer = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    layer[rideNumber] = [];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      const positionAlarms = getAlarmsFromAnnouncementsInPosition(ride.positions[positionIndex]);
      if (positionAlarms.length > 0) {
        layer[rideNumber][positionIndex] = [
          calculatePercentage(positionIndex, ride.positions.length),
          100 / ride.positions.length,
        ];
      }
    }
  }
  return layer;
};

/**
 * Create timeline ecodrive layer
 * @param {IRide[]} rides
 * @returns {IRidePlayerTimelineEcoDriveLayer}
 */
export const createTimelineEcoDriveLayer = (rides: IRide[]): IRidePlayerTimelineEcoDriveLayer => {
  const layer: IRidePlayerTimelineEcoDriveLayer = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    layer[rideNumber] = [];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      const ecoScore = ride.positions[positionIndex].ecoScore;
      if (ecoScore) {
        const ecoDrive = getEcoDrive(ecoScore);
        layer[rideNumber][positionIndex] = [
          calculatePercentage(positionIndex, ride.positions.length),
          ecoDrive.color,
          100 / ride.positions.length,
          ecoScore,
        ];
      }
    }
  }
  return layer;
};

/**
 * Create timeline event layer
 * @param {IRide[]} rides
 * @returns {IRidePlayerTimelineEventLayer}
 */
export const createTimelineEventLayer = (rides: IRide[]): IRidePlayerTimelineEventLayer => {
  const layer: IRidePlayerTimelineEventLayer = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    layer[rideNumber] = [];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      const positionAlarms = getEventsFromAnnouncementsInPosition(ride.positions[positionIndex]);
      if (positionAlarms.length > 0) {
        layer[rideNumber][positionIndex] = [
          calculatePercentage(positionIndex, ride.positions.length),
          100 / ride.positions.length,
        ];
      }
    }
  }
  return layer;
};

/**
 * Create timeline speedlimit layer
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @param {IRide[]} rides
 * @returns {IRidePlayerTimelineSpeedlimitLayer}
 */
export const createTimelineSpeedlimitLayer = (
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  rides: IRide[]
): IRidePlayerTimelineSpeedlimitLayer => {
  const layer: IRidePlayerTimelineSpeedlimitLayer = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    layer[rideNumber] = [];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      const positionSpeedlimit = getSpeedlimit(
        speedlimitSettings,
        ride.positions[positionIndex].speed
      );
      layer[rideNumber][positionIndex] = [
        calculatePercentage(positionIndex, ride.positions.length),
        positionSpeedlimit.color,
        100 / ride.positions.length,
        ride.positions[positionIndex].speed,
      ];
    }
  }
  return layer;
};

/**
 * Add new position to timeline layer
 * @param {IRideTimelineLayers} timelineLayers
 * @param {number} rideNumber
 * @param {IRidePosition} newPosition
 * @returns {void}
 */
export const addNewPositionToTimelineLayersForRide = (
  timelineLayers: IRideTimelineLayers,
  rideNumber: number,
  newPosition: IRidePosition
): void => {
  const itemsLength = Object.keys(timelineLayers[rideNumber].rideLayer).length;

  for (let positionIndex = 0; positionIndex <= itemsLength; positionIndex += 1) {
    timelineLayers[rideNumber].rideLayer[positionIndex] = calculatePercentage(
      positionIndex,
      itemsLength
    );
    const newPositionAlarms = getAlarmsFromAnnouncementsInPosition(newPosition);
    if (newPositionAlarms.length > 0) {
      timelineLayers[rideNumber].alarmLayer[positionIndex] = calculatePercentage(
        positionIndex,
        itemsLength
      );
    }
  }
};

export const createActiveRideLine = (googleMap: google.maps.Map): google.maps.Polyline => {
  return GoogleMapsService.createPolyline(
    googleMap,
    [],
    ACTIVE_RIDE_COLOR,
    ACTIVE_RIDE_OPACITY,
    ACTIVE_RIDE_SIZE,
    ACTIVE_RIDE_ZINDEX
  );
};

/**
 * Create active ride lines
 * @param {google.maps.Map} googleMap
 * @param {IRide[]} rides
 * @param {string} color
 * @returns {IPlayedLines}
 */
export const createActiveRideLines = (
  googleMap: google.maps.Map,
  rides: IRide[],
  color: string
): IPlayedLines => {
  const playedRideLines: IPlayedLines = {};
  rides.forEach((ride: IRide) => {
    playedRideLines[ride.rideData.key] = GoogleMapsService.createPolyline(
      googleMap,
      [],
      color, // PLAYED_LINE_RIDE_LAYER_COLOR, // ACTIVE_RIDE_COLOR,
      ACTIVE_RIDE_OPACITY,
      ACTIVE_RIDE_SIZE,
      1
    );
  });
  return playedRideLines;
};

export const hideMarkersOnMap = (
  activeRidehideMarkers: number,
  markers: IRideMapMarkers,
  markerTooltips: IRideMapMarkerTooltips,
  alarmMarkers: IRideMapCombinedAlarmMarkers,
  alarmPositionMarkerTooltips: IRideMapAlarmPositionMarkerTooltips,
  showAlarms: boolean
): void => {
  markers[activeRidehideMarkers].forEach((marker: google.maps.Marker) => {
    if (marker.getMap()) {
      marker.setMap(null);
    }
  });

  if (showAlarms) {
    Object.keys(alarmMarkers[activeRidehideMarkers]).forEach((positionIndex: string) => {
      const markerData = alarmMarkers[activeRidehideMarkers][Number(positionIndex)];
      if (markerData.marker && markerData.marker.getMap()) {
        markerData.marker.setMap(null);
      }
    });
    alarmPositionMarkerTooltips[activeRidehideMarkers].forEach(
      (infoWindow: google.maps.OverlayView | null) => infoWindow && infoWindow.setMap(null)
    );
  }
};

export const showMarkersOnMap = (
  activeRideShowMarkersOnMap: number,
  markers: IRideMapMarkers,
  alarmMarkers: IRideMapCombinedAlarmMarkers,
  showAlarms: boolean,
  map: google.maps.Map
): void => {
  markers[activeRideShowMarkersOnMap].forEach((marker: google.maps.Marker) => {
    if (!marker.getMap()) {
      marker.setMap(map);
    }
  });

  if (showAlarms) {
    Object.keys(alarmMarkers[activeRideShowMarkersOnMap]).forEach((positionIndex: string) => {
      const markerData = alarmMarkers[activeRideShowMarkersOnMap][Number(positionIndex)];
      if (markerData.marker && !markerData.marker.getMap()) {
        markerData.marker.setMap(map);
      }
    });
  }
};

/**
 * Create alarm lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @returns {IRideMapAlarmLines}
 */
export const createAlarmLines = (
  map: google.maps.Map | null,
  rides: IRide[],
  selectedRides: number[],
  alarmsPaths: IRideAlarmLinePaths
): IRideMapAlarmLines => {
  const alarmLines: IRideMapAlarmLines = { 1: [] };
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    alarmsPaths[rideNumber] = {};
    rides[rideIndex].positions.forEach((_position: IRidePosition, index: number) => {
      const pathGroupNumber = Object.keys(alarmsPaths[rideNumber]).length;
      const prevPosition = rides[rideIndex].positions[index - 1];
      const currentPosition = rides[rideIndex].positions[index];
      const currentPositionAlarms = getAlarmsFromAnnouncementsInPosition(currentPosition);
      let prevPositionAlarms: IAnnouncement[] = [];
      if (prevPosition) {
        prevPositionAlarms = getAlarmsFromAnnouncementsInPosition(prevPosition);
      }
      // First position
      if (!prevPosition && currentPositionAlarms.length > 0) {
        alarmsPaths[rideNumber][pathGroupNumber + 1] = [
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
        ];
      }
      // If prev position exists and has zero alarms and current position has alarms create new polyline path
      if (prevPosition && prevPositionAlarms.length === 0 && currentPositionAlarms.length > 0) {
        alarmsPaths[rideNumber][pathGroupNumber + 1] = [
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
        ];
      }
      // If prev position exist and current position has alarm push position to current polyline path
      if (prevPosition && prevPositionAlarms.length > 0 && currentPositionAlarms.length > 0) {
        alarmsPaths[rideNumber][pathGroupNumber].push({
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        });
      }
    });

    // If alarm path contains only one item, duplicate it. Polyline needs at least two points.
    Object.keys(alarmsPaths[rideNumber]).forEach((pathGroupNumber: string) => {
      if (alarmsPaths[rideNumber][pathGroupNumber].length === 1) {
        alarmsPaths[rideNumber][pathGroupNumber].push(alarmsPaths[rideNumber][pathGroupNumber][0]);
      }
    });

    alarmLines[rideNumber] = [];
    Object.keys(alarmsPaths[rideNumber]).forEach((pathGroup: string) => {
      const polyline = GoogleMapsService.createPolyline(
        selectedRides.includes(rideNumber) ? map : null,
        alarmsPaths[rideNumber][pathGroup],
        ALARM_ACTIVE_LINE_COLOR,
        ALARM_LINE_OPACITY,
        ALARM_LINE_SIZE,
        ALARM_LINE_ZINDEX
      );
      polyline.set('originalColor', ALARM_ACTIVE_LINE_COLOR);
      if (alarmLines[rideNumber]) {
        alarmLines[rideNumber].push(polyline);
      }
    });
  }
  return alarmLines;
};

/**
 * Create waiting lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {IRideWaitingLinePahts} waitingsPaths
 * @returns {IRideWaitingLinePaths}
 */
export const createWaitingLines = (
  map: google.maps.Map,
  rides: IRide[],
  waitingsPaths: IRideWaitingLinePaths,
  enableWaitings: boolean,
  activeRide: number
): IRideMapWaitingLines => {
  const waitingLines: IRideMapWaitingLines = { 1: [] };
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    waitingsPaths[rideNumber] = {};
    rides[rideIndex].positions.forEach((_position: IRidePosition, index: number) => {
      const pathGroupNumber = Object.keys(waitingsPaths[rideNumber]).length;
      const currentPosition = rides[rideIndex].positions[index];

      if (currentPosition.type === IS_PAUSE_POSITION) {
        waitingsPaths[rideNumber][pathGroupNumber + 1] = [
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
        ];
      }
    });

    Object.keys(waitingsPaths[rideNumber]).forEach((pathGroup: string) => {
      const polyline = GoogleMapsService.createPolyline(
        enableWaitings ? map : null,
        waitingsPaths[rideNumber][pathGroup],
        rideNumber === activeRide ? WAITING_LINE_COLOR : DARK_GREY_LINE_COLOR,
        WAITING_LINE_OPACITY,
        WAITING_LINE_SIZE,
        WAITING_LINE_ZINDEX
      );
      if (waitingLines[rideNumber]) {
        waitingLines[rideNumber].push(polyline);
      }
    });
  }

  return waitingLines;
};

/**
 * Update alarm lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @returns {IRideMapAlarmLines}
 */
export const updateAlarmLines = (
  map: google.maps.Map | undefined,
  rides: IRide[],
  alarmLines: IRideMapAlarmLines,
  alarmsPaths: IRideAlarmLinePaths,
  rideNumber: number,
  currentPosition: IRidePosition
): void => {
  const pathGroupNumber = Object.keys(alarmsPaths[rideNumber]).length;
  const rideIndex = rideNumber - 1;
  const prevPositionIndex = rides[rideIndex].positions.length - 2;
  const prevPosition = rides[rideIndex].positions[prevPositionIndex];
  const currentPositionAlarms = getAlarmsFromAnnouncementsInPosition(currentPosition);
  let prevPositionAlarms: IAnnouncement[] = [];
  if (prevPosition) {
    prevPositionAlarms = getAlarmsFromAnnouncementsInPosition(prevPosition);
  }
  // First position
  if (!prevPosition && currentPositionAlarms.length > 0) {
    alarmsPaths[rideNumber][pathGroupNumber + 1] = [
      { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
    ];
  }
  // If prev position exists and has zero alarms and current position has alarms create new polyline path
  if (prevPosition && prevPositionAlarms.length === 0 && currentPositionAlarms.length > 0) {
    alarmsPaths[rideNumber][pathGroupNumber + 1] = [
      { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
    ];
  }
  // If prev position exist and current position has alarm push position to current polyline path
  if (prevPosition && prevPositionAlarms.length > 0 && currentPositionAlarms.length > 0) {
    alarmsPaths[rideNumber][pathGroupNumber].push({
      lat: currentPosition.gpsLat,
      lng: currentPosition.gpsLon,
    });
  }

  // If alarm path contains only one item, duplicate it. Polyline needs at least two points.
  Object.keys(alarmsPaths[rideNumber]).forEach((pathGroupNum: string) => {
    if (alarmsPaths[rideNumber][pathGroupNum].length === 1) {
      alarmsPaths[rideNumber][pathGroupNum].push(alarmsPaths[rideNumber][pathGroupNum][0]);
    }
  });

  Object.keys(alarmsPaths[rideNumber]).forEach((pathGroup: string) => {
    const polyline = GoogleMapsService.createPolyline(
      map,
      alarmsPaths[rideNumber][pathGroup],
      ALARM_ACTIVE_LINE_COLOR,
      ALARM_LINE_OPACITY,
      ALARM_LINE_SIZE,
      ALARM_LINE_ZINDEX
    );
    if (alarmLines[rideNumber]) {
      alarmLines[rideNumber].push(polyline);
    }
  });
};

/**
 * Update ecoDrive lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {IRideMapEcoDriveLines} ecoDriveLines
 * @param {ecoDrivePaths} ecoDrivePaths
 * @param {number} rideNumber
 * @param {IRidePosition} currentPosition
 * @returns {IRideMapEcoDriveLines}
 */
export const updateEcoDriveLines = (
  map: google.maps.Map | undefined | null,
  rides: IRide[],
  ecoDriveLines: IRideMapEcoDriveLines,
  ecoDrivePaths: IRideEcoDriveLinePaths,
  rideNumber: number,
  currentPosition: IRidePosition
): void => {
  const pathGroupNumber = Object.keys(ecoDrivePaths[rideNumber]).length;
  const rideIndex = rideNumber - 1;
  const prevPositionIndex = rides[rideIndex].positions.length - 2;
  const prevPosition = rides[rideIndex].positions[prevPositionIndex];

  // First position
  if (!prevPosition && currentPosition.ecoScore) {
    ecoDrivePaths[rideNumber][pathGroupNumber + 1] = {
      ecoScore: getEcoDrive(currentPosition.ecoScore),
      positions: [
        {
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        },
      ],
    };
  }
  // If prev position exists and has zero speedlimit and current position has different speed create new polyline path
  if (
    prevPosition &&
    currentPosition.ecoScore !== null &&
    ecoDrivePaths[rideNumber][pathGroupNumber] &&
    ecoDrivePaths[rideNumber][pathGroupNumber].ecoScore.key !==
      getEcoDrive(currentPosition.ecoScore).key
  ) {
    ecoDrivePaths[rideNumber][pathGroupNumber + 1] = {
      ecoScore: getEcoDrive(currentPosition.ecoScore),
      positions: [
        {
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        },
      ],
    };
  }

  // If prev position exist and current position has ecoScore push position to current polyline path
  if (
    prevPosition &&
    currentPosition.ecoScore &&
    ecoDrivePaths[rideNumber][pathGroupNumber] &&
    ecoDrivePaths[rideNumber][pathGroupNumber].ecoScore.key ===
      getEcoDrive(currentPosition.ecoScore).key
  ) {
    ecoDrivePaths[rideNumber][pathGroupNumber].positions.push({
      lat: currentPosition.gpsLat,
      lng: currentPosition.gpsLon,
    });
  }

  // "Connect" all pathGroups with each other
  Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroupNum: string) => {
    const currentPathGroup = ecoDrivePaths[rideNumber][Number(pathGroupNum)];
    const nextPathGroup = ecoDrivePaths[rideNumber][Number(pathGroupNum) + 1];
    if (nextPathGroup && currentPathGroup) {
      currentPathGroup.positions.push(nextPathGroup.positions[0]);
    }
  });

  // If ecoDrive path contains only one item, duplicate it. Polyline needs at least two points.
  Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroupNum: string) => {
    if (ecoDrivePaths[rideNumber][pathGroupNum].positions.length === 1) {
      ecoDrivePaths[rideNumber][pathGroupNum].positions.push(
        ecoDrivePaths[rideNumber][pathGroupNum].positions[0]
      );
    }
  });

  Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroup: string) => {
    const polyline = GoogleMapsService.createPolyline(
      map,
      ecoDrivePaths[rideNumber][pathGroup].positions,
      ecoDrivePaths[rideNumber][pathGroup].ecoScore.color,
      ALARM_LINE_OPACITY,
      ALARM_LINE_SIZE,
      ALARM_LINE_ZINDEX
    );
    if (ecoDriveLines[rideNumber]) {
      ecoDriveLines[rideNumber].push(polyline);
    }
  });
};

/**
 * Update event lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @returns {IRideMapAlarmLines}
 */
export const updateEventLines = (
  map: google.maps.Map | undefined,
  rides: IRide[],
  eventLines: IRideMapEventLines,
  eventsPaths: IRideEventLinePaths,
  rideNumber: number,
  currentPosition: IRidePosition
): void => {
  const pathGroupNumber = Object.keys(eventsPaths[rideNumber]).length;
  const rideIndex = rideNumber - 1;
  const prevPositionIndex = rides[rideIndex].positions.length - 2;
  const prevPosition = rides[rideIndex].positions[prevPositionIndex];
  const currentPositionEvents = getEventsFromAnnouncementsInPosition(currentPosition);
  let prevPositionEvents: IAnnouncement[] = [];
  if (prevPosition) {
    prevPositionEvents = getEventsFromAnnouncementsInPosition(prevPosition);
  }
  // First position
  if (!prevPosition && currentPositionEvents.length > 0) {
    eventsPaths[rideNumber][pathGroupNumber + 1] = [
      { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
    ];
  }
  // If prev position exists and has zero events and current position has events create new polyline path
  if (prevPosition && prevPositionEvents.length === 0 && currentPositionEvents.length > 0) {
    eventsPaths[rideNumber][pathGroupNumber + 1] = [
      { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
    ];
  }
  // If prev position exist and current position has event push position to current polyline path
  if (prevPosition && prevPositionEvents.length > 0 && currentPositionEvents.length > 0) {
    eventsPaths[rideNumber][pathGroupNumber].push({
      lat: currentPosition.gpsLat,
      lng: currentPosition.gpsLon,
    });
  }

  // If event path contains only one item, duplicate it. Polyline needs at least two points.
  Object.keys(eventsPaths[rideNumber]).forEach((pathGroupNum: string) => {
    if (eventsPaths[rideNumber][pathGroupNum].length === 1) {
      eventsPaths[rideNumber][pathGroupNum].push(eventsPaths[rideNumber][pathGroupNum][0]);
    }
  });

  Object.keys(eventsPaths[rideNumber]).forEach((pathGroup: string) => {
    const polyline = GoogleMapsService.createPolyline(
      map,
      eventsPaths[rideNumber][pathGroup],
      EVENT_ACTIVE_LINE_COLOR,
      EVENT_LINE_OPACITY,
      EVENT_LINE_SIZE,
      EVENT_LINE_ZINDEX
    );
    polyline.set('originalColor', EVENT_ACTIVE_LINE_COLOR);
    if (eventLines[rideNumber]) {
      eventLines[rideNumber].push(polyline);
    }
  });
};

/**
 * Get speedlimit by key
 * @param {IRidePlayerSpeedLimitsSettings} speedLimits
 * @param {number} speed
 * @returns {string}
 */
const getSpeedlimitKey = (speedLimits: IRidePlayerSpeedLimitsSettings, speed: number): string => {
  if (speed >= speedLimits.minimumSpeed && speed < speedLimits.warningSpeed) {
    return 'minimumSpeed';
  }
  if (speed >= speedLimits.warningSpeed && speed < speedLimits.maximumSpeed) {
    return 'warningSpeed';
  }
  if (speed >= speedLimits.maximumSpeed) {
    return 'maximumSpeed';
  }
  return 'normalSpeed';
};

/**
 * Get speedlimit color by key
 * @param {string} speedlimit
 * @returns {string}
 */
const getSpeedlimitColor = (speedlimit: string): string => {
  switch (speedlimit) {
    case 'minimumSpeed': {
      return '#F7CC00';
    }
    case 'warningSpeed': {
      return '#F26827';
    }
    case 'maximumSpeed': {
      return '#B50536';
    }
    default: {
      return '#05B590';
    }
  }
};

/**
 * Create speedlimit paths
 * @param {IRide[]} rides
 * @param {IRideSpeedLimitLinePaths} speedLimitPaths
 * @param {IRidePlayerSpeedLimitsSettings} speedLimitsSettings
 * @returns {IRideSpeedLimitLinePaths}
 */
const createSpeedLimitPaths = (
  rides: IRide[],
  speedLimitPaths: IRideSpeedLimitLinePaths,
  speedLimitsSettings: IRidePlayerSpeedLimitsSettings
): IRideSpeedLimitLinePaths => {
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    speedLimitPaths[rideNumber] = {};
    rides[rideIndex].positions.forEach((_position: IRidePosition, index: number) => {
      const pathGroupNumber = Object.keys(speedLimitPaths[rideNumber]).length;
      const prevPosition = rides[rideIndex].positions[index - 1];
      const currentPosition = rides[rideIndex].positions[index];

      // First position
      if (!prevPosition && currentPosition) {
        speedLimitPaths[rideNumber][pathGroupNumber + 1] = {
          speedlimit: getSpeedlimitKey(speedLimitsSettings, currentPosition.speed),
          rideNumber,
          positions: [{ lat: currentPosition.gpsLat, lng: currentPosition.gpsLon }],
        };
      }
      // If prev position exists and has zero speedlimit and current position has different speed create new polyline path
      if (
        prevPosition &&
        speedLimitPaths[rideNumber][pathGroupNumber] &&
        speedLimitPaths[rideNumber][pathGroupNumber].speedlimit !==
          getSpeedlimitKey(speedLimitsSettings, currentPosition.speed)
      ) {
        speedLimitPaths[rideNumber][pathGroupNumber + 1] = {
          speedlimit: getSpeedlimitKey(speedLimitsSettings, currentPosition.speed),
          rideNumber,
          positions: [{ lat: currentPosition.gpsLat, lng: currentPosition.gpsLon }],
        };
      }
      // If prev position exist and current position has speedlimit push position to current polyline path
      if (
        prevPosition &&
        speedLimitPaths[rideNumber][pathGroupNumber] &&
        speedLimitPaths[rideNumber][pathGroupNumber].speedlimit ===
          getSpeedlimitKey(speedLimitsSettings, currentPosition.speed)
      ) {
        speedLimitPaths[rideNumber][pathGroupNumber].positions.push({
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        });
      }
    });

    // "Connect" all pathGroups with each other
    Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroupNumber: string) => {
      const currentPathGroup = speedLimitPaths[rideNumber][Number(pathGroupNumber)];
      const nextPathGroup = speedLimitPaths[rideNumber][Number(pathGroupNumber) + 1];
      if (nextPathGroup && currentPathGroup) {
        currentPathGroup.positions.push(nextPathGroup.positions[0]);
      }
    });

    // If speedlimits path contains only one item, duplicate it. Polyline needs at least two points.
    Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroupNumber: string) => {
      if (speedLimitPaths[rideNumber][pathGroupNumber].positions.length === 1) {
        speedLimitPaths[rideNumber][pathGroupNumber].positions.push(
          speedLimitPaths[rideNumber][pathGroupNumber].positions[0]
        );
      }
    });
  }
  return speedLimitPaths;
};

/**
 * Create speedlimit lines for specified ride
 * @param {google.maps.Map} map
 * @param {speedLimitPaths} speedLimitPaths
 * @param {IRideMapSpeedLimitLines} speedLimitLines
 * @param {number} rideNumber
 */
export const createSpeedLimitLinesForRide = (
  map: google.maps.Map | undefined | null,
  speedLimitPaths: IRideSpeedLimitLinePaths,
  speedLimitLines: IRideMapSpeedLimitLines,
  rideNumber: number
): void => {
  if (speedLimitLines[rideNumber]) {
    speedLimitLines[rideNumber].forEach((polyline: google.maps.Polyline) => {
      polyline.setMap(null);
    });
  }
  if (speedLimitPaths[rideNumber]) {
    speedLimitLines[rideNumber] = [];
    Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroup: string) => {
      if (speedLimitPaths[rideNumber][pathGroup].speedlimit !== 'normalSpeed') {
        const polyline = GoogleMapsService.createPolyline(
          map || null,
          speedLimitPaths[rideNumber][pathGroup].positions,
          getSpeedlimitColor(speedLimitPaths[rideNumber][pathGroup].speedlimit),
          ALARM_LINE_OPACITY,
          ALARM_LINE_SIZE,
          ALARM_LINE_ZINDEX
        );
        if (speedLimitLines[rideNumber]) {
          speedLimitLines[rideNumber].push(polyline);
        }
      }
    });
  }
};

/**
 * Create alarm lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {ISelectedRides} selectedRides
 * @param {IRideSpeedLimitLinePaths} speedLimitPaths
 * @param {IRidePlayerSpeedLimitsSettings} speedLimitsSettings
 * @returns {IRideMapAlarmLines}
 */
export const createSpeedLimitLines = (
  map: google.maps.Map | undefined | null,
  rides: IRide[],
  selectedRides: number[],
  speedLimitPaths: IRideSpeedLimitLinePaths,
  speedLimitsSettings: IRidePlayerSpeedLimitsSettings,
  isRideActive: number | false,
  activeLayer: TRideActiveLayer,
  showAllRides: boolean
): IRideMapSpeedLimitLines => {
  const speedLimitLines: IRideMapSpeedLimitLines = {};
  speedLimitPaths = createSpeedLimitPaths(rides, speedLimitPaths, speedLimitsSettings);
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    speedLimitLines[rideNumber] = [];
    Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroup: string) => {
      if (speedLimitPaths[rideNumber][pathGroup].speedlimit !== 'normalSpeed') {
        const polyline = GoogleMapsService.createPolyline(
          selectedRides.includes(rideNumber) ? map : null,
          speedLimitPaths[rideNumber][pathGroup].positions,
          isRideActive !== false && isRideActive !== rideNumber && !showAllRides
            ? DARK_GREY_LINE_COLOR
            : getSpeedlimitColor(speedLimitPaths[rideNumber][pathGroup].speedlimit),
          ALARM_LINE_OPACITY,
          ALARM_LINE_SIZE,
          showAllRides ||
            (isRideActive !== false && activeLayer === IS_RIDE_LAYER && isRideActive === rideNumber)
            ? ALARM_LINE_ZINDEX
            : 1
        );
        if (speedLimitLines[rideNumber]) {
          speedLimitLines[rideNumber].push(polyline);
        }
      }
    });
  }
  return speedLimitLines;
};

/**
 * Create ecoDrive paths
 * @param {IRide[]} rides
 * @param {IRideEcoDriveLinePaths} ecoDrivePaths
 * @returns {IRideEcoDriveLinePaths}
 */
const createEcoDrivePaths = (
  rides: IRide[],
  ecoDrivePaths: IRideEcoDriveLinePaths
): IRideEcoDriveLinePaths => {
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    ecoDrivePaths[rideNumber] = {};
    rides[rideIndex].positions.forEach((_position: IRidePosition, index: number) => {
      const pathGroupNumber = Object.keys(ecoDrivePaths[rideNumber]).length;
      const prevPosition = rides[rideIndex].positions[index - 1];
      const currentPosition = rides[rideIndex].positions[index];

      // First position
      if (!prevPosition && currentPosition && currentPosition.ecoScore !== null) {
        ecoDrivePaths[rideNumber][pathGroupNumber + 1] = {
          ecoScore: getEcoDrive(currentPosition.ecoScore),
          positions: [
            {
              lat: currentPosition.gpsLat,
              lng: currentPosition.gpsLon,
            },
          ],
        };
      }
      // If prev position exists and has no ecoscore and current position exists and has ecoscore, create new polyline path
      if (
        prevPosition &&
        prevPosition.ecoScore === null &&
        currentPosition &&
        currentPosition.ecoScore !== null
      ) {
        ecoDrivePaths[rideNumber][pathGroupNumber + 1] = {
          ecoScore: getEcoDrive(currentPosition.ecoScore),
          positions: [
            {
              lat: currentPosition.gpsLat,
              lng: currentPosition.gpsLon,
            },
          ],
        };
      }
      // If prev position exists and has zero ecodrive and current position has different ecodrive  create new polyline path
      if (
        prevPosition &&
        currentPosition.ecoScore !== null &&
        ecoDrivePaths[rideNumber][pathGroupNumber] &&
        currentPosition.ecoScore !== null &&
        ecoDrivePaths[rideNumber][pathGroupNumber].ecoScore.key !==
          getEcoDrive(currentPosition.ecoScore).key
      ) {
        ecoDrivePaths[rideNumber][pathGroupNumber + 1] = {
          ecoScore: getEcoDrive(currentPosition.ecoScore),
          positions: [
            {
              lat: currentPosition.gpsLat,
              lng: currentPosition.gpsLon,
            },
          ],
        };
      }
      // If prev position exist and current position has ecoScore push position to current polyline path
      if (
        prevPosition &&
        currentPosition.ecoScore !== null &&
        ecoDrivePaths[rideNumber][pathGroupNumber] &&
        ecoDrivePaths[rideNumber][pathGroupNumber].ecoScore.key ===
          getEcoDrive(currentPosition.ecoScore).key
      ) {
        ecoDrivePaths[rideNumber][pathGroupNumber].positions.push({
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        });
      }
    });

    // "Connect" all pathGroups with each other
    Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroupNumber: string) => {
      const currentPathGroup = ecoDrivePaths[rideNumber][Number(pathGroupNumber)];
      const nextPathGroup = ecoDrivePaths[rideNumber][Number(pathGroupNumber) + 1];
      if (nextPathGroup && currentPathGroup) {
        currentPathGroup.positions.push(nextPathGroup.positions[0]);
      }
    });
    // If ecoScore path contains only one item, duplicate it. Polyline needs at least two points.
    Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroupNumber: string) => {
      if (ecoDrivePaths[rideNumber][pathGroupNumber].positions.length === 1) {
        ecoDrivePaths[rideNumber][pathGroupNumber].positions.push(
          ecoDrivePaths[rideNumber][pathGroupNumber].positions[0]
        );
      }
    });
  }
  return ecoDrivePaths;
};

/**
 * Create ecoDrive lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {IRideEcoDriveLinePaths} ecoDrivePaths
 * @returns {IRideMapEcoDriveLines}
 */
export const createEcoDriveLines = (
  map: google.maps.Map | null,
  rides: IRide[],
  ecoDrivePaths: IRideEcoDriveLinePaths
): IRideMapEcoDriveLines => {
  const ecoDriveLines: IRideMapEcoDriveLines = { 1: [] };
  ecoDrivePaths = createEcoDrivePaths(rides, ecoDrivePaths);
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    if (!ecoDriveLines[rideNumber]) {
      ecoDriveLines[rideNumber] = [];
    }
    Object.keys(ecoDrivePaths[rideNumber]).forEach((pathGroup: string) => {
      if (ecoDrivePaths[rideNumber][pathGroup].ecoScore.key !== 'good') {
        const polyline = GoogleMapsService.createPolyline(
          map,
          ecoDrivePaths[rideNumber][pathGroup].positions,
          ecoDrivePaths[rideNumber][pathGroup].ecoScore.color,
          ALARM_LINE_OPACITY,
          ALARM_LINE_SIZE,
          ALARM_LINE_ZINDEX
        );
        polyline.set('originalColor', ecoDrivePaths[rideNumber][pathGroup].ecoScore.color);
        if (ecoDriveLines[rideNumber]) {
          ecoDriveLines[rideNumber].push(polyline);
        }
      }
    });
  }
  return ecoDriveLines;
};

const createEventsPaths = (
  rides: IRide[],
  eventsPaths: IRideEventLinePaths
): IRideEventLinePaths => {
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    eventsPaths[rideNumber] = {};
    rides[rideIndex].positions.forEach((_position: IRidePosition, index: number) => {
      const pathGroupNumber = Object.keys(eventsPaths[rideNumber]).length;
      const prevPosition = rides[rideIndex].positions[index - 1];
      const currentPosition = rides[rideIndex].positions[index];
      const currentPositionEvents = getEventsFromAnnouncementsInPosition(currentPosition);
      let prevPositionEvents: IAnnouncement[] = [];
      if (prevPosition) {
        prevPositionEvents = getEventsFromAnnouncementsInPosition(prevPosition);
      }
      // First position
      if (!prevPosition && currentPositionEvents.length > 0) {
        eventsPaths[rideNumber][pathGroupNumber + 1] = [
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
        ];
      }
      // If prev position exists and has zero events and current position has events create new polyline path
      if (prevPosition && prevPositionEvents.length === 0 && currentPositionEvents.length > 0) {
        eventsPaths[rideNumber][pathGroupNumber + 1] = [
          { lat: currentPosition.gpsLat, lng: currentPosition.gpsLon },
        ];
      }
      // If prev position exist and current position has alarm push position to current polyline path
      if (prevPosition && prevPositionEvents.length > 0 && currentPositionEvents.length > 0) {
        eventsPaths[rideNumber][pathGroupNumber].push({
          lat: currentPosition.gpsLat,
          lng: currentPosition.gpsLon,
        });
      }
    });

    // If alarm path contains only one item, duplicate it. Polyline needs at least two points.
    Object.keys(eventsPaths[rideNumber]).forEach((pathGroupNumber: string) => {
      if (eventsPaths[rideNumber][pathGroupNumber].length === 1) {
        eventsPaths[rideNumber][pathGroupNumber].push(eventsPaths[rideNumber][pathGroupNumber][0]);
      }
    });
  }
  return eventsPaths;
};

/**
 * Create event lines
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {IRideEventLinePaths} eventsPaths
 * @returns {IRideMapEventLines}
 */
export const createEventLines = (
  map: google.maps.Map | null,
  rides: IRide[],
  eventsPaths: IRideEventLinePaths
): IRideMapEventLines => {
  const eventLines: IRideMapEventLines = { 1: [] };
  eventsPaths = createEventsPaths(rides, eventsPaths);
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    if (!eventLines[rideNumber]) {
      eventLines[rideNumber] = [];
    }
    Object.keys(eventsPaths[rideNumber]).forEach((pathGroup: string) => {
      const polyline = GoogleMapsService.createPolyline(
        map,
        eventsPaths[rideNumber][pathGroup],
        EVENT_ACTIVE_LINE_COLOR,
        EVENT_LINE_OPACITY,
        EVENT_LINE_SIZE,
        EVENT_LINE_ZINDEX
      );
      polyline.set('originalColor', EVENT_ACTIVE_LINE_COLOR);
      if (eventLines[rideNumber]) {
        eventLines[rideNumber].push(polyline);
      }
    });
  }
  return eventLines;
};

/**
 * Updated speedlimit lines by new position
 * @param {google.maps.Map | undefined | null} map
 * @param {IRideMapSpeedLimitLines} speedLimitLines
 * @param {IRideSpeedLimitLinePaths} speedLimitPaths
 * @param {IRidePlayerSpeedLimitsSettings} speedLimitsSettings
 * @param {number} rideNumber
 * @param {IRidePosition} currentPosition
 * @returns {void}
 */
export const updateSpeedLimitLines = (
  map: google.maps.Map | undefined | null,
  speedLimitLines: IRideMapSpeedLimitLines,
  speedLimitPaths: IRideSpeedLimitLinePaths,
  speedLimitsSettings: IRidePlayerSpeedLimitsSettings,
  rideNumber: number,
  currentPosition: IRidePosition
): void => {
  const pathGroupNumber = Object.keys(speedLimitPaths[rideNumber]).length;

  // First position
  if (currentPosition) {
    speedLimitPaths[rideNumber][pathGroupNumber + 1] = {
      speedlimit: getSpeedlimitKey(speedLimitsSettings, currentPosition.speed),
      rideNumber,
      positions: [{ lat: currentPosition.gpsLat, lng: currentPosition.gpsLon }],
    };
  }
  // If prev position exists and has zero speedlimit and current position has different speed create new polyline path
  if (
    speedLimitPaths[rideNumber][pathGroupNumber] &&
    speedLimitPaths[rideNumber][pathGroupNumber].speedlimit !==
      getSpeedlimitKey(speedLimitsSettings, currentPosition.speed)
  ) {
    speedLimitPaths[rideNumber][pathGroupNumber + 1] = {
      speedlimit: getSpeedlimitKey(speedLimitsSettings, currentPosition.speed),
      rideNumber,
      positions: [{ lat: currentPosition.gpsLat, lng: currentPosition.gpsLon }],
    };
  }
  // If prev position exist and current position has speedlimit push position to current polyline path
  if (
    speedLimitPaths[rideNumber][pathGroupNumber] &&
    speedLimitPaths[rideNumber][pathGroupNumber].speedlimit ===
      getSpeedlimitKey(speedLimitsSettings, currentPosition.speed)
  ) {
    speedLimitPaths[rideNumber][pathGroupNumber].positions.push({
      lat: currentPosition.gpsLat,
      lng: currentPosition.gpsLon,
    });
  }
  // If speedlimits path contains only one item, duplicate it. Polyline needs at least two points.
  Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroup: string) => {
    if (speedLimitPaths[rideNumber][pathGroup].positions.length === 1) {
      speedLimitPaths[rideNumber][pathGroup].positions.push(
        speedLimitPaths[rideNumber][pathGroup].positions[0]
      );
    }
  });

  Object.keys(speedLimitPaths[rideNumber]).forEach((pathGroup: string) => {
    if (speedLimitPaths[rideNumber][pathGroup].speedlimit !== 'normalSpeed') {
      const polyline = GoogleMapsService.createPolyline(
        map || null,
        speedLimitPaths[rideNumber][pathGroup].positions,
        getSpeedlimitColor(speedLimitPaths[rideNumber][pathGroup].speedlimit),
        ALARM_LINE_OPACITY,
        ALARM_LINE_SIZE,
        ALARM_LINE_ZINDEX
      );
      if (speedLimitLines[rideNumber]) {
        speedLimitLines[rideNumber].push(polyline);
      }
    }
  });
};

/**
 * Get paths for ride from positions
 * @param {IRide} ride
 * @return {google.maps.LatLng[]}
 */
export const getPathFromRidePositions = (ride: IRide): google.maps.LatLng[] => {
  const paths: google.maps.LatLng[] = [];
  ride.positions.forEach((position: IRidePosition) => {
    paths.push(new google.maps.LatLng(position.gpsLat, position.gpsLon));
  });
  return paths;
};

/**
 * Format time
 * @param {number} timestamp
 * @returns {string}
 */
export const formatTime = (timestamp: number): string => {
  const dt = new Date(timestamp * 1000);
  const hr = dt.getHours();
  const m = `0${dt.getMinutes()}`;
  return `${hr} : ${m.substring(-2)}`;
};

/**
 * Set all played ride lines
 * @returns {void}
 */
export const resetAllPlayedRideLines = (playedRideLines: IPlayedLines): void => {
  Object.keys(playedRideLines).forEach((rideNumber: string) => {
    const polyline: google.maps.Polyline = playedRideLines[rideNumber];
    if (polyline) {
      polyline.setPath([]);
    }
  });
};

/**
 * Update timeline position marker
 * @param {google.maps.Marker | null} timelinePositionMarker
 * @param {google.maps.OverlayView | null} timelinePositionMarkerTooltip
 * @param {IntlShape} intl
 * @param {number} positionIndex
 * @param {IRidePosition} position
 * @param {IRideDetail} rideData
 * @returns {void}
 */
export const updateTimelinePositionMarker = (
  timelinePositionMarker: google.maps.Marker | null,
  timelinePositionMarkerTooltip: google.maps.OverlayView | null,
  intl: IntlShape,
  positionIndex: number,
  position: IRidePosition,
  rideData: IRideDetail
): void => {
  if (timelinePositionMarker) {
    if (timelinePositionMarkerTooltip) {
      timelinePositionMarkerTooltip.setMap(null);
    }
    const newPosition = new google.maps.LatLng(position.gpsLat, position.gpsLon);
    timelinePositionMarker.setPosition(newPosition);
    const oldPosition = timelinePositionMarker.getPosition();
    if (
      oldPosition &&
      newPosition.lat !== oldPosition.lat &&
      newPosition.lng !== oldPosition.lng &&
      timelinePositionMarkerTooltip
    ) {
      timelinePositionMarkerTooltip.setMap(null);
    }
    timelinePositionMarkerTooltip = RidePlayerMarkerService.createTimelineMarkerTooltip(
      positionIndex,
      position,
      rideData,
      intl
    ) as google.maps.OverlayView;
  }
};
