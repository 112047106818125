import * as React from 'react';

const Error404: React.FC = () => {
  return (
    <div className="w-page">
      <div className="w-page-content">
        <div className="page-header">
          <h4 className="page-header__title">404 Error</h4>
        </div>
        <div className="w-error w-error--404 d-flex align-items-center" />
      </div>
    </div>
  );
};

export default Error404;
