import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import ab2str from 'arraybuffer-to-string';
import configJs from '../../config';
import { hideMessage, showMessage } from '../components/Toastr/ToastService';
import JWTService from './JWTService';
import { AXIOS_BASE_URL, AXIOS_HEADERS, AXIOS_TIMEOUT } from './HttpService';

const ACL_ROLE_NOT_FOUND = 'ACL_ROLE_NOT_FOUND';
const ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED';
export const STATUS_OK = 'ok';
export const STATUS_ERROR = 'error';
export const IS_OK = 200;
export const IS_BAD_REQUEST = 400;

const detailed500Error = (error: any, url = '') => {
  const additionalMessage =
    configJs.apiService.detailed500Error.isEnabled &&
    error.response.data &&
    error.response.data.message
      ? error.response.data.message
      : null;

  let errorMessage = 'Unexpected Api Error';
  if (error.response.data && error.response.data.toast) {
    errorMessage = error.response.data.toast.message;
  }
  if (additionalMessage) {
    errorMessage = `Unexpected Api Error ${additionalMessage}`;
  }
  if (error.response.data && error.response.data.toast) {
    if (configJs.apiService.detailed500Error.showRequestUrl) {
      errorMessage = `Unexpected Api Error for URL ${url}`;
    }
    showMessage(
      '',
      errorMessage,
      error.response.data.toast.type.toLowerCase(),
      error.response.data.toast.duration,
      error.response.data.toast.messagePayload || null
    );
  } else {
    console.info('ApiService error: ', error);
    showMessage('', errorMessage, 'error', null, null);
  }
};

export const RESPONSE_OK = 'ok';
export const RESPONSE_ERROR = 'error';

const serializeObjectIntoQueryParams = (obj: any) => {
  let str = '';
  let index = 0;
  Object.keys(obj).forEach((key: string) => {
    if (index === 0) {
      str += `?${key}=${obj[key]}`;
    } else {
      str += `&${key}=${obj[key]}`;
    }
    index += 1;
  });
  return str;
};

const clearLocalStorage = () => {
  const language = localStorage.getItem('language');
  localStorage.clear();
  if (language) {
    localStorage.setItem('language', language);
  }
};

export default class ApiService {
  static async upload(url: string, data: any = null, enableMessage = true): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout: AXIOS_TIMEOUT,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
    };
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    if (axiosConfig.headers) {
      delete axiosConfig.headers['Content-Type'];
    }

    // axiosConfig.headers.Authorization = JWTService.getToken();
    const instance = axios.create(axiosConfig);
    const formData = new FormData();
    formData.append('file', data);
    return instance
      .post(url, formData)
      .then((response) => {
        if (enableMessage && response.data.toast) {
          console.info(response.data.toast.message);
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 500 &&
          error.response.data.message &&
          error.response.data.message === ACL_ROLE_NOT_FOUND
        ) {
          showMessage('', 'ACL Role not found.', 'error');
          return error;
        }
        if (!error.response) {
          if (enableMessage) {
            showMessage('', 'Unexpected Api Error', 'error');
          }
          return error;
        }
        // Handle normal 400
        if (error.response.status === 400 && !error.response.data.form) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.respnse.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        }
        // Handle form 400
        else if (
          error.response.status === 400 &&
          error.response.data.form &&
          error.response.data.form.validation &&
          !error.response.data.form.validation.passed
        ) {
          // const inputNames = Object.keys(error.response.data.form.validation.errors);
          // inputNames.forEach((inputName: string) => {
          //   store.dispatch(
          //     formActions.setValidationError(
          //       inputName,
          //       error.response.data.form.validation.errors[inputName]
          //     )
          //   );
          // });
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        } else if (error.response.status === 401 && !error.response.data.toast) {
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 401 && error.response.data.toast) {
          showMessage('', error.response.data.toast.message, 'warning');
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          showMessage('', error.response.data.toast.message, 'warning');
        } else {
          showMessage('', 'Unexpected Api Error', 'error');
        }
        return error.response;
      });
  }

  static async post(
    url: string,
    data: any = null,
    enableMessage = true,
    timeout = AXIOS_TIMEOUT,
    customToastDuraration?: number
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
    };
    // axiosConfig.headers.Authorization = JWTService.getToken();
    // axiosConfig.timeout = timeout;
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    const instance = axios.create(axiosConfig);
    return instance
      .post(url, data)
      .then((response) => {
        if (
          enableMessage &&
          response.data.toast &&
          response.data.toast.message !== 'reports.job.created'
        ) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            customToastDuraration || response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        } else {
          hideMessage();
        }
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          if (enableMessage) {
            showMessage('', 'Unexpected API Error', 'error');
          }
          return error;
        }
        if (
          error.response.status === 500 &&
          error.response.data.message &&
          error.response.data.message === ACL_ROLE_NOT_FOUND
        ) {
          showMessage('', 'ACL Role not found.', 'error');
          return error;
        }
        // Handle normal 400
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          !error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        }
        // Handle form 400
        else if (
          error.response.status === 400 &&
          error.response.data.form &&
          error.response.data.form.validation &&
          !error.response.data.form.validation.passed
        ) {
          // const inputNames = Object.keys(error.response.data.form.validation.errors);
          // inputNames.forEach((inputName: string) => {
          //   store.dispatch(
          //     formActions.setValidationError(
          //       inputName,
          //       error.response.data.form.validation.errors[inputName]
          //     )
          //   );
          // });
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
          // Handle new form 400
        } else if (error.response.status === 400 && error.response.data.passed === false) {
          return error.response;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        } else if (error.response.status === 401 && !error.response.data.toast) {
          if (error.response.data.message === ACCESS_TOKEN_EXPIRED && url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 401 && error.response.data.toast) {
          showMessage('', error.response.data.toast.message, 'warning');
          if (url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          if (error.response.data.toast) {
            showMessage('', error.response.data.toast.message, 'warning');
          }
        } else if (error.response.status === 409) {
          showMessage(
            '',
            error.response.data.toast.message,
            error.response.data.toast.type.toLowerCase(),
            error.response.data.toast.duration,
            error.response.data.toast.messagePayload || null
          );
        } else {
          detailed500Error(error, url);
        }
        console.info('error.response: ', error.response);
        return error.response;
      });
  }

  static async postWithAbort(
    url: string,
    data: any = null,
    signal: AbortSignal | undefined,
    enableMessage = true,
    timeout = AXIOS_TIMEOUT,
    customToastDuraration?: number
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
    };
    // axiosConfig.headers.Authorization = JWTService.getToken();
    // axiosConfig.timeout = timeout;
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    const instance = axios.create(axiosConfig);
    return instance
      .post(url, data, { signal })
      .then((response) => {
        if (
          enableMessage &&
          response.data.toast &&
          response.data.toast.message !== 'reports.job.created'
        ) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            customToastDuraration || response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          if (enableMessage && !signal) {
            showMessage('', 'Unexpected API Error', 'error');
          }
          return error;
        }
        if (
          error.response.status === 500 &&
          error.response.data.message &&
          error.response.data.message === ACL_ROLE_NOT_FOUND
        ) {
          showMessage('', 'ACL Role not found.', 'error');
          return error;
        }
        // Handle normal 400
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          !error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        }
        // Handle form 400
        else if (
          error.response.status === 400 &&
          error.response.data.form &&
          error.response.data.form.validation &&
          !error.response.data.form.validation.passed
        ) {
          // const inputNames = Object.keys(error.response.data.form.validation.errors);
          // inputNames.forEach((inputName: string) => {
          //   store.dispatch(
          //     formActions.setValidationError(
          //       inputName,
          //       error.response.data.form.validation.errors[inputName]
          //     )
          //   );
          // });
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
          // Handle new form 400
        } else if (error.response.status === 400 && error.response.data.passed === false) {
          return error.response;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        } else if (error.response.status === 401 && !error.response.data.toast) {
          if (error.response.data.message === ACCESS_TOKEN_EXPIRED && url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 401 && error.response.data.toast) {
          showMessage('', error.response.data.toast.message, 'warning');
          if (url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          if (error.response.data.toast) {
            showMessage('', error.response.data.toast.message, 'warning');
          }
        } else if (error.response.status === 409) {
          showMessage(
            '',
            error.response.data.toast.message,
            error.response.data.toast.type.toLowerCase(),
            error.response.data.toast.duration,
            error.response.data.toast.messagePayload || null
          );
        } else {
          detailed500Error(error, url);
        }
        return error.response;
      });
  }

  static async put(
    url: string,
    data: Record<any, any>,
    enableMessage = true,
    customToastDuraration?: number
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout: AXIOS_TIMEOUT,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
    };
    // axiosConfig.headers.Authorization = JWTService.getToken();
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    const instance = axios.create(axiosConfig);
    return instance
      .put(url, data)
      .then((response) => {
        if (enableMessage && response.data.toast) {
          // console.info(response.data.toast.message);
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            customToastDuraration || response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          if (enableMessage) {
            showMessage('', 'Unexpected API Error', 'error');
          }
          return error;
        }
        // Handle normal 400
        if (
          error.response.status === 400 &&
          !error.response.data.form &&
          error.response.data.toast
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        }
        // Handle new form 400
        else if (error.response.status === 400 && error.response.data.passed === false) {
          if (error.response.data.toast && error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          }
          return error.response;
        }
        // Handle old form 400
        else if (
          error.response.status === 400 &&
          error.response.data.form &&
          error.response.data.form.validation.errors
        ) {
          // const inputNames = Object.keys(error.response.data.form.validation.errors);
          // inputNames.forEach((inputName: string) => {
          //   if (Array.isArray(error.response.data.form.validation.errors[inputName])) {
          //     error.response.data.form.validation.errors[inputName].forEach(
          //       (errorMessage: string) => {
          //         store.dispatch(formActions.setValidationError(inputName, errorMessage));
          //       }
          //     );
          //   } else {
          //     store.dispatch(
          //       formActions.setValidationError(
          //         inputName,
          //         error.response.data.form.validation.errors[inputName]
          //       )
          //     );
          //   }
          //  });
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        } else if (error.response.status === 401) {
          // if (error.response.data.message === ACCESS_TOKEN_EXPIRED) {
          //   clearLocalStorage();
          //   window.location.href = '/login';
          // }
          showMessage('', 'Unauthorized', 'error');
          clearLocalStorage();
          window.location.href = '/';
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          showMessage('', error.response.data.toast.message, 'warning');
        } else if (error.response.status === 409) {
          showMessage(
            '',
            error.response.data.toast.message,
            error.response.data.toast.type.toLowerCase(),
            error.response.data.toast.duration,
            error.response.data.toast.messagePayload || null
          );
        } else {
          detailed500Error(error, url);
        }
        return error.response;
      });
  }

  static async get(
    url: string,
    data: number | string | null | Record<string, any> | undefined = undefined,
    enableMessage = false,
    noApiUrl = false,
    requestTimeout = AXIOS_TIMEOUT
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: noApiUrl ? './' : AXIOS_BASE_URL,
      timeout: requestTimeout,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
    };
    // axiosConfig.headers.Authorization = JWTService.getToken();
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    // if (noApiUrl) {
    //   axiosConfig.baseURL = './';
    // }
    const instance = axios.create(axiosConfig);
    if (data) {
      url += serializeObjectIntoQueryParams(data);
    }
    return instance
      .get(url)
      .then((response) => {
        if (response.data.toast) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          if (enableMessage) {
            showMessage('', 'Unexpected API Error', 'error');
          }
          return error;
        }
        if (error.response.status === 409) {
          if (enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              error.response.data.toast.type.toLowerCase(),
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          }
          return error;
        }
        if (error.response.status === 401) {
          if (!enableMessage) {
            console.info('Unauthorized', 'error');
            window.location.href = '/';
          } else if (error.response.data.toast) {
            showMessage(
              '',
              error.response.data.toast.message,
              error.response.data.toast.type.toLowerCase(),
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          }
          clearLocalStorage();
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          if (error.response.data.toast) {
            showMessage('', error.response.data.toast.message, 'warning');
          }
        } else {
          if (!enableMessage) {
            return false;
          }
          detailed500Error(error, url);
        }
        console.info('error.response: ', error.response);
        return error.response;
      });
  }

  static async delete(
    url: string,
    data: any = null,
    enableMessage = true,
    timeout = AXIOS_TIMEOUT,
    customToastDuraration?: number
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout,
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
      },
      data,
    };
    // axiosConfig.headers.Authorization = JWTService.getToken();
    // axiosConfig.timeout = timeout;
    // axiosConfig.headers.baseURL = `${configJs.Backend.url}/api`;
    const instance = axios.create(axiosConfig);

    return instance
      .delete(url, { data })
      .then((response) => {
        if (
          enableMessage &&
          response.data.toast &&
          response.data.toast.message !== 'reports.job.created'
        ) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            customToastDuraration || response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          if (enableMessage) {
            showMessage('', 'Unexpected API Error', 'error');
          }
          return error;
        }
        if (
          error.response.status === 500 &&
          error.response.data.message &&
          error.response.data.message === ACL_ROLE_NOT_FOUND
        ) {
          showMessage('', 'ACL Role not found.', 'error');
          return error;
        }
        // Handle normal 400
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          !error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        }
        // Handle form 400
        else if (
          error.response.status === 400 &&
          error.response.data.form &&
          error.response.data.form.validation &&
          !error.response.data.form.validation.passed
        ) {
          // const inputNames = Object.keys(error.response.data.form.validation.errors);
          // inputNames.forEach((inputName: string) => {
          //   store.dispatch(
          //     formActions.setValidationError(
          //       inputName,
          //       error.response.data.form.validation.errors[inputName]
          //     )
          //   );
          // });
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
          // Handle new form 400
        } else if (error.response.status === 400 && error.response.data.passed === false) {
          return error.response;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.toast &&
          error.response.status === 400 &&
          error.response.data.form
        ) {
          if (error.response.data.toast.message && enableMessage) {
            showMessage(
              '',
              error.response.data.toast.message,
              'warning',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Undefined Error Message', 'warning');
          }
        } else if (error.response.status === 401 && !error.response.data.toast) {
          if (error.response.data.message === ACCESS_TOKEN_EXPIRED && url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 401 && error.response.data.toast) {
          showMessage('', error.response.data.toast.message, 'warning');
          if (url !== 'v1/auth/sign-in') {
            clearLocalStorage();
            window.location.href = '/';
          }
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          if (error.response.data.toast) {
            showMessage('', error.response.data.toast.message, 'warning');
          }
        } else if (error.response.status === 409) {
          showMessage(
            '',
            error.response.data.toast.message,
            error.response.data.toast.type.toLowerCase(),
            error.response.data.toast.duration,
            error.response.data.toast.messagePayload || null
          );
        } else {
          detailed500Error(error, url);
        }
        return error.response;
      });
  }

  static async postDownload(
    url: string,
    mimeType: string,
    body: Record<any, any>
  ): Promise<AxiosResponse> {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: configJs.Backend.url,
      timeout: AXIOS_TIMEOUT,
      responseType: 'arraybuffer',
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
        Accept: mimeType,
      },
    };
    // config.headers.Authorization = JWTService.getToken();
    // config.responseType = 'arraybuffer';
    // config.baseURL = configJs.Backend.url;
    // config.headers.Accept = mimeType;

    const instance = axios.create(axiosConfig);
    return instance
      .post(url, body)
      .then((response: AxiosResponse) => {
        if (response.data.toast) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        const data = JSON.parse(ab2str(error.response.data));
        if (error.response.status === 401) {
          if (data.message === ACCESS_TOKEN_EXPIRED) {
            clearLocalStorage();
            window.location.href = '/';
          }
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 403) {
          if (data.toast.message) {
            showMessage(
              '',
              data.toast.message,
              'error',
              data.toast.duration,
              data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          showMessage('', data.toast.message, 'warning');
        } else if (error.response.status === 400 && data.passed === undefined) {
          if (data.toast) {
            showMessage(
              '',
              data.toast.message,
              'warning',
              data.toast.duration,
              data.toast.messagePayload || null
            );
          } else {
            showMessage('', data.toast.message, 'warning', data.toast.duration);
          }
        } else {
          showMessage('', data.toast.message, 'error', data.toast.duration);
        }
        return error.response;
      });
  }

  static async download(
    url: string,
    mimeType: string,
    signal?: AbortSignal | undefined
  ): Promise<AxiosResponse> {
    // const config = { ...axiosConfig };
    const axiosConfig: AxiosRequestConfig = {
      baseURL: configJs.Backend.url,
      timeout: AXIOS_TIMEOUT,
      responseType: 'arraybuffer',
      headers: {
        ...AXIOS_HEADERS,
        Authorization: JWTService.getToken(),
        Accept: mimeType,
        // exposedHeaders: ['Content-Disposition'],
      },
    };

    // config.headers.Authorization = JWTService.getToken();
    // config.responseType = 'arraybuffer';
    // config.baseURL = configJs.Backend.url;
    // config.headers.Accept = mimeType;
    // config.headers.exposedHeaders = ['Content-Disposition'];

    const instance = axios.create(axiosConfig);
    return instance
      .get(url, { signal })
      .then((response) => {
        if (response.data.toast) {
          showMessage(
            '',
            response.data.toast.message,
            response.data.toast.type.toLowerCase(),
            response.data.toast.duration,
            response.data.toast.messagePayload || null
          );
        }
        return response;
      })
      .catch((error) => {
        if (!error.response && !signal) {
          showMessage('', 'Unexpected API Error', 'error');
          return error;
        }
        if (error.response.status === 401) {
          if (error.response.data.message === ACCESS_TOKEN_EXPIRED) {
            clearLocalStorage();
            window.location.href = '/';
          }
          showMessage('', 'Unauthorized', 'error');
        } else if (error.response.status === 403) {
          if (error.response.data.toast.message) {
            showMessage(
              '',
              error.response.data.toast.message,
              'error',
              error.response.data.toast.duration,
              error.response.data.toast.messagePayload || null
            );
          } else {
            showMessage('', 'Forbidden', 'error');
          }
        } else if (error.response.status === 404) {
          if (error.response.data.toast) {
            showMessage('', error.response.data.toast.message, 'warning');
          } else {
            showMessage('', 'Unexpected API Error', 'error');
            return false;
          }
        } else {
          detailed500Error(error, url);
        }
        return error.response;
      });
  }
}
