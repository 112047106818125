import { ICustomerListResponseData } from '../../interfaces/apiResponse';
import ApiService, { RESPONSE_OK } from '../../Services/ApiService';
import JSONValidationService from '../../Services/JSONValidationService';
import SearchService from '../../Services/SearchService';
import { ICustomer } from '../../store/recoil/customers';
import CustomerDataSchema from './CustomerDataSchema.json';
import * as HTTPService from '../../Services/HttpService';

export interface ICustomersApiResponse {
  customers: ICustomerListResponseData[] | null;
  status: 'ok';
}

const CustomerService = {
  /** Get customer by id */
  getById(customers: ICustomer[], id: number): ICustomer | null {
    // const customer = customers.filter((customerData: ICustomer) => customerData.id === id);
    const customer = customers.find((customerData: ICustomer) => customerData.id === id);
    if (customer) {
      return customer;
    }
    return null;
  },

  /** Get customer names and ids */
  getCustomersNamesByIds(customers: ICustomer[], ids: number[]): string {
    let customersNames = '';
    const filteredCustomers = customers.filter((customer: ICustomer) => ids.includes(customer.id));
    filteredCustomers.forEach((i: any, key: number) => {
      if (filteredCustomers.length - 1 === key) {
        customersNames += i.name;
      } else {
        customersNames += `${i.name}, `;
      }
    });
    return customersNames;
  },

  /** Get customer id by name */
  getCustomerIdByName(customers: ICustomer[], name: string): number | null {
    const customerData = customers.find((customer: ICustomer) => customer.name === name);
    if (customerData) {
      return customerData.id;
    }
    return null;
  },

  addSelectedTempCustomer(state: number[], customerId: number): number[] {
    const newState: number[] = [...state, customerId];
    return newState;
  },

  removeSelectedTempCustomer(state: number[], customerId: number): number[] {
    const newState = state.filter((item) => item !== customerId);
    return newState;
  },

  async getCustomers(): Promise<ICustomer[] | null> {
    const response = await HTTPService.get<ICustomersApiResponse>('/v1/user/customer/list', {});
    if (response) {
      JSONValidationService.validateSchema(CustomerDataSchema, response, 'Customers Data Schema');
      const customersFromResponse = (response as ICustomersApiResponse).customers || [];
      const customers: ICustomer[] = customersFromResponse.map(
        (customerData: ICustomerListResponseData) => {
          const customer: ICustomer = {
            id: customerData.id,
            name: customerData.name,
            shortName: customerData.short_name,
            branchId: null,
            serverId: customerData.server_id,
            label: customerData.label || null,
          };
          return customer;
        }
      );
      return customers;
    }
    return null;
  },

  async saveSelectedCustomers(selectedCustomers: number[]): Promise<any> {
    const data = {
      selected_customers: selectedCustomers,
    };
    const response = await ApiService.post('v1/user-settings/selected_customers/save', data);
    if (response.data && response.data.status === RESPONSE_OK) {
      return response.data;
    }
    return false;
  },

  getCustomerNameById(customers: ICustomer[], id: number): string {
    const customer = customers.find(
      (customerData: ICustomer) => String(customerData.id) === String(id)
    );

    if (customer) {
      return customer.name;
    }
    return '';
  },

  filter(customers: ICustomer[], filterString: string): ICustomer[] {
    if (filterString === '') {
      return [];
    }

    let tempString = filterString;

    tempString = tempString.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');
    const regexp = new RegExp(SearchService.removeAccent(tempString.toLowerCase()));
    return customers.filter(
      (customerItem) =>
        customerItem.name &&
        regexp.test(SearchService.removeAccent(customerItem.name.toLowerCase()))
    );
  },
};

export default CustomerService;
