import { AxiosError, AxiosResponse } from 'axios';
import Config from '../../../config';
import * as HttpService from '../../Services/HttpService';

export interface IMaintenanceStatusResponse {
  status: string;
  maintenance?: {
    status: string;
    start: string;
    end: string;
    message: string;
  };
  // {"status":"maintenance","maintenance":{"status":"active","start":"2022-10-12 06:30:56","end":"2022-10-13 07:30:56","message":"planned_maintenance_full"}}
}

export const IS_MAINTENANCE = 'maintenance';
export const IS_MAINTENANCE_ACTIVE = 'active';
export const IS_MAINTENANCE_PLANNED = 'planned';

export const checkStatus = async (
  isEnabled: boolean
): Promise<IMaintenanceStatusResponse | false | AxiosResponse<unknown, any>> => {
  if (!Config.appStatus.name) {
    console.warn('App status is not defined and cannot be checked.');
    return false;
  }
  if (!isEnabled) {
    console.warn('Checking app status is disabled.');
    return false;
  }
  const url = `${Config.appStatus.url}/${Config.appStatus.name}`;
  const data = await HttpService.get<IMaintenanceStatusResponse | AxiosError | false>(url);
  if (data) {
    return data as IMaintenanceStatusResponse;
  }
  return false;
};

export const isMaintenanceTokenPresent = () => {
  const maintenanceToken = localStorage.getItem('[maintenanceToken]');
  if (maintenanceToken) {
    return true;
  }
  return false;
};

export const isMaintenanceTokenValid = () => {
  const maintenanceToken = localStorage.getItem('maintenanceToken');
  if (maintenanceToken === Config.maintenanceToken) {
    return true;
  }
  return false;
};
