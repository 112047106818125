import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import dayjs from 'dayjs';
import bowser from 'bowser';
import { Buffer } from 'buffer';
import localforage from 'localforage';
// import { useIntl } from 'react-intl';
import UserService from '../../Services/UserService';
import JWTService from '../../Services/JWTService';
import * as VehiclesState from '../../store/recoil/vehicles';
// import * as VehiclesDateState from '../../store/recoil/vehiclesData';
// import * as OnlinePanelState from '../OnlinePanel/OnlinePanelState';
// import * as EcoDriveState from '../Graphs/EcoDrive/EcoDriveState';
import * as CustomersState from '../../store/recoil/customers';
import * as BluecoinState from '../OnlineMapNew/Bluecoins/BluecoinState';
import {
  // ILastRideDistanceCumulative,
  IVehicle,
  // IVehicleData,
  // IVehicleDriver,
  IVehicleGdprDataAllowed,
  // IVehicleLastRide,
  // IVehiclePosition,
  IVehicles,
  // IVehiclesData,
  // TVehicleStatus,
} from '../Vehicles/interfaces';
// import {
//   IOnlinePanelDefaultTemplateChange,
//   IOnlinePanelUserTemplateChange,
//   IVehicleOnlinePanels,
//   IVehicleOnlinePanelState,
// } from '../OnlinePanel/OnlinePanelState';
// import * as ExportState from '../Export/ExportState';
import {
  EVENT_CONNECT,
  EVENT_CONNECTION_CLOSE,
  EVENT_CONNECTION_ERROR,
  EVENT_CONNECTION_OPEN,
  EVENT_DATA,
  EVENT_DATA_BULK,
  EVENT_DATA_CHANGE_BULK,
  EVENT_DEFAULT_TEMPLATE_CHANGE,
  EVENT_DEFAULT_TEMPLATE_CHANGE_BULK,
  EVENT_ECO_DRIVE_VIOLATION,
  EVENT_ECO_DRIVE_VIOLATION_BULK,
  EVENT_EMPTY_VEHICLES,
  // EVENT_EXPORT_IN_PROGRESS,
  EVENT_JOIN,
  EVENT_LOGOUT,
  EVENT_REPORT_CREATED,
  EVENT_RIDE_CHANGED,
  EVENT_SELECTED_CUSTOMERS,
  EVENT_SELECTED_VEHICLES,
  EVENT_USER_TEMPLATE_CHANGE,
  EVENT_USER_VEHICLES,
  EVENT_VEHICLE_POSITION,
  EVENT_VEHICLE_POSITION_BULK,
  EVENT_EMPTY_USER_DATA,
  EVENT_USER_DATA,
  EVENT_BLUECOIN_POSITION_BULK,
  EVENT_EMPTY_GDPR_SETTINGS,
  EVENT_GDPR_SETTINGS,
  EVENT_IS_OPEN,
} from './constants';
// import { IAnnouncement } from '../RidePlayer/interfaces';
// import { IDownload } from '../Export/ExportState';
import { IEcoDriveState, IGraphData } from '../Graphs/EcoDrive/EcoDriveState';
import { localForageStore } from '../../store/localForageStore';
import * as OnlineMapState from '../OnlineMapNew/OnlineMapState';
import * as GraphState from '../Graphs/GraphState';
// import * as RidePlayerState from '../RidePlayer/RidePlayerState';
import * as AppState from '../../store/recoil/AppState';
// import NavigatorService from '../Navigator/NavigatorService';
// import VehicleService from '../../Services/VehicleService';
import {
  IEventDataChangePayload,
  // IEventRideEdViolationPayload,
  // IEventVehiclePositionPayload,
  // IMessageExportInProgress,
  // IMessageReportCreated,
  ISocketConnectionData,
  // ISocketServerBulkMessage,
  // IUnitDataProcessorDefaultTemplateChange,
  // IUnitDataProcessorNavigatorData,
  // IUserTemplateChange,
  // IVehicleDataMinimal,
  // IVehicleMinimal,
  IWebSocketEvent,
  IMessageLogout,
  // EventBluecoinPositionPayload,
  // BluecoinPositionData,
} from './interfaces';
import { getGdprMapSettings } from './SocketConnectionService';
// import { BluecoinData, BluecoinsData } from '../OnlineMapNew/Bluecoins/interfaces';
import { CorsWorkerService as Worker } from '../../Services/CorsWorkerService';
// import { IS_ACTIVE } from '../Bluecoins/constants';
// import { convertDuration } from '../RidePlayer/RidePlayerService';

// interface IMessageVehicles {
//   [vehicleId: number]: IEventDataChangePayload;
// }

export const SocketConnectionWorker = await new Worker(
  new URL('./SocketConnectionWorker.ts', import.meta.url),
  { type: 'module', name: 'socket-connection-worker' }
).createWorker();

// const IS_BINARY = 'binary';

export default function SocketConnection(): null {
  // const { formatMessage: f } = useIntl();
  const browserTabId = useRecoilValue<string>(AppState.browserTabId);
  const gdprSettings = useRecoilValue(AppState.gdprSettings);
  // const [ecoDrive, setEcoDriveData] = useRecoilState<IGraphData>(EcoDriveState.graphData);
  // const [lastRideDistanceCumulative, setLastRideDistanceCumulative] =
  //   useRecoilState<ILastRideDistanceCumulative | null>(VehiclesState.lastRideDistanceCumulative);
  const isRidePlayerOpen = useRecoilValue<number | boolean>(OnlineMapState.isRidePlayerOpen);
  const isGraphOpen = useRecoilValue<number | boolean>(GraphState.isGraphOpen);
  const isBluecoinsOpen = useRecoilValue<boolean>(BluecoinState.isBluecoinsOpen);
  // const setBluecoins = useSetRecoilState(BluecoinState.bluecoins);
  // const setShowLastRideChangedWarning = useSetRecoilState<boolean>(
  //   RidePlayerState.showLastRideChangedWarning
  // );
  const selectedVehicles = useRecoilValue<number[]>(VehiclesState.selectedVehicles);
  const selectedCustomers = useRecoilValue<number[]>(CustomersState.selectedCustomers);
  const setIsVehicleGdprDataAllowed = useSetRecoilState<IVehicleGdprDataAllowed>(
    VehiclesState.isVehicleGdprDataAllowed
  );
  // const setOnlinePanel = useSetRecoilState<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  // const setIsActiveExport = useSetRecoilState<false | string>(ExportState.isActive);
  // const setExportData = useSetRecoilState<IMessageReportCreated | null>(ExportState.data);
  // const setDownloads = useSetRecoilState<IDownload[]>(ExportState.downloads);
  // const setUserOnlinePanelTemplate = useSetRecoilState<IOnlinePanelUserTemplateChange>(
  //   OnlinePanelState.userOnlinePanelTemplate
  // );
  // const setDefaultOnlinePanelTemplate = useSetRecoilState<IOnlinePanelDefaultTemplateChange>(
  //   OnlinePanelState.defaultOnlinePanelTemplate
  // );
  // const setVehiclesData = useSetRecoilState<IVehiclesData>(VehiclesDateState.vehiclesData);
  // const setVehicles = useSetRecoilState<IVehicles>(VehiclesState.vehicles);
  const navigatorVehicles = useRecoilValue<IVehicles>(VehiclesState.vehicles);

  // Connection status (true if connected)
  const [connectionStatus, setConnectionStatus] = React.useState<boolean>(false);

  const [channelsToBeJoined, setChannelsToBeJoined] = React.useState<string[]>([]);
  const [filteredSelectedVehicle, setFilteredSelectedVehicle] = React.useState<number[]>([]);
  // const isOnlinePanelOpen = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  // const queryClient = useQueryClient();
  // const isLoadingExtraDataForWidgets = useRecoilValue<boolean>(
  //   OnlinePanelState.isLoadingExtraDataForWidgets
  // );

  const handleOnConnectionError = (_error: string | false) => {
    if (JWTService.isSocketServerTokenExpired()) {
      UserService.logOut();
    }
    setConnectionStatus(false);
  };

  const handleOnConnectionOpen = React.useCallback(async () => {
    setConnectionStatus(true);

    // Send selectedVehicles
    const dataSelectedVehicles = {
      event: EVENT_SELECTED_VEHICLES,
      payload: selectedVehicles,
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataSelectedVehicles);

    // Send selectedCustomers
    const dataSelectedCustomers = {
      event: EVENT_SELECTED_CUSTOMERS,
      payload: selectedCustomers,
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataSelectedCustomers);

    // Send user vehicles
    const vehicleIds = await localForageStore.getItem('vehicleIds');
    const dataVehicles = {
      event: EVENT_USER_VEHICLES,
      payload: vehicleIds,
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataVehicles);

    // Set is ride player open (or reset in when page is reloaded)
    const dataIsRidePlayerOpen = {
      event: EVENT_IS_OPEN,
      payload: ['ridePlayer', isRidePlayerOpen],
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataIsRidePlayerOpen);

    const dataIsGraphOpen = {
      event: EVENT_IS_OPEN,
      payload: ['graph', isGraphOpen],
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataIsGraphOpen);

    const dataIsBluecoinsMapOpen = {
      event: EVENT_IS_OPEN,
      payload: ['bluecoinsMap', isBluecoinsOpen],
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(dataIsBluecoinsMapOpen);

    // Send GDPR settings
    const dataGdpr = {
      event: EVENT_GDPR_SETTINGS,
      payload: getGdprMapSettings(gdprSettings, UserService.getUserId(), UserService.getDriverId()),
    };
    SocketConnectionWorker.postMessage(dataGdpr);
  }, [
    selectedVehicles,
    selectedCustomers,
    isRidePlayerOpen,
    isGraphOpen,
    isBluecoinsOpen,
    gdprSettings,
  ]);

  const handleOnConnectionClose = () => {
    setConnectionStatus(false);
  };

  const handleConnect = async (jwt: string) => {
    // const vehicles = await localForageStore.getItem<number[]>('vehicleIds');
    const bowserParsedData = bowser.parse(window.navigator.userAgent);
    const browser = `${bowserParsedData.browser.name} ${bowserParsedData.browser.version} / ${
      bowserParsedData.os.name
    } ${bowserParsedData.os.versionName || bowserParsedData.os.version}`;
    const socketConnectionData: ISocketConnectionData = {
      browser,
      browserTabId,
      selectedVehicles,
      selectedCustomers,
      isLastRidePlayerOpen: isRidePlayerOpen,
    };

    const data64 = Buffer.from(JSON.stringify(socketConnectionData)).toString('base64');

    const data = {
      event: EVENT_CONNECT,
      // payload: { jwt, selectedVehicles, selectedCustomers },
      payload: { jwt, data: data64, app: 'CCC' },
    };
    SocketConnectionWorker.postMessage(data);
  };

  /**
   * When logout event is received, signout user
   * @param {IMessageLogout} message
   * @returns {void}
   */
  const handleOnLogoutMessageReceived = (message: IMessageLogout): void => {
    if (message.userId === UserService.getUserId()) {
      UserService.autoLogOut();
    }
  };

  /**
   * When report is asynchroniously created, show toast
   * @param {IMessageReportCreated} message
   * @returns {void}
   */
  // const handleOnReportCreatedMessageReceived = React.useCallback(
  //   (message: IMessageReportCreated): void => {
  //     if (message) {
  //       setDownloads((oldState: IDownload[]) => {
  //         const job = oldState.find((item: IDownload) => item.jobId === message.jobId);
  //         if (job) {
  //           const updatedJob: IDownload = {
  //             ...job,
  //             percentage: 100,
  //             generating_end: dayjs().toISOString(),
  //             code: message.toast.messagePayload.code,
  //             filename: '',
  //           };
  //           const newState = oldState.filter((item: IDownload) => item.jobId !== message.jobId);
  //           return [...newState, updatedJob];
  //         }
  //         return oldState;
  //       });
  //       setExportData(message);
  //     }
  //   },
  //   [setDownloads, setExportData]
  // );

  // const handleOnExportInProgress = React.useCallback(
  //   (message: IMessageExportInProgress): void => {
  //     if (message && message.percentage) {
  //       setIsActiveExport((oldState: false | string) => {
  //         if (oldState !== message.requestId) {
  //           return message.requestId;
  //         }
  //         return oldState;
  //       });
  //       setDownloads((oldState: IDownload[]) => {
  //         const job = oldState.find((item: IDownload) => item.jobId === message.requestId);
  //         if (job) {
  //           const updatedJob: IDownload = {
  //             ...job,
  //             percentage: Number(message.percentage.toFixed(0)),
  //           };
  //           const newState = [
  //             ...oldState.filter((item: IDownload) => item.jobId !== message.requestId),
  //             updatedJob,
  //           ];
  //           return newState;
  //         }
  //         const newJob: IDownload = {
  //           jobId: message.requestId,
  //           percentage: Number(message.percentage.toFixed(0)),
  //           isDownloaded: false,
  //           generating_start: dayjs().toISOString(),
  //           generating_end: null,
  //           filename: '',
  //           code: '',
  //         };
  //         return [...oldState, newJob];
  //       });
  //     }
  //   },
  //   [setDownloads, setIsActiveExport]
  // );

  // const handleUserTemplateChange = React.useCallback(
  //   (userTemplateChange: IUserTemplateChange) => {
  //     setUserOnlinePanelTemplate((oldState: IOnlinePanelUserTemplateChange) => {
  //       const newState: IOnlinePanelUserTemplateChange = {
  //         customerId: userTemplateChange.template?.customerId
  //           ? userTemplateChange.template?.customerId
  //           : oldState.customerId,
  //         template: userTemplateChange.template,
  //         userId: userTemplateChange.userId,
  //         vehicleId: userTemplateChange.vehicleId,
  //       };
  //       return newState;
  //     });
  //   },
  //   [setUserOnlinePanelTemplate]
  // );

  // const handleRideChanged = React.useCallback(
  //   (vehicleId: number) => {
  //     if (isRidePlayerOpen === vehicleId) {
  //       // if (String(isRidePlayerOpen) === String(vehicleId)) {
  //       // setReloadLastRide(true);
  //       setShowLastRideChangedWarning(true);
  //     }
  //     if (isOnlinePanelOpen === vehicleId && isLoadingExtraDataForWidgets) {
  //       queryClient.invalidateQueries({
  //         queryKey: ['widgetsDataForOnlinePanel', isOnlinePanelOpen],
  //       });
  //     }
  //   },
  //   [isRidePlayerOpen, setShowLastRideChangedWarning]
  // );

  // const updateEcoDriveState = (
  //   state: { [name: string]: IEcoDriveState },
  //   data: { [name: string]: IEcoDriveState }
  // ) => {
  //   return { ...state, ...data };
  // };

  const updateEcoDriveState = (state: IGraphData, data: IGraphData) => {
    return { ...state, ...data };
  };

  // const getDistanceCumulative = React.useCallback(
  //   (message: IEventVehiclePositionPayload, lastDistance: ILastRideDistanceCumulative | null) => {
  //     const { distance, vehicleId } = message;
  //     if (isRidePlayerOpen === vehicleId) {
  //       const newValue =
  //         (lastDistance && lastDistance[vehicleId] ? lastDistance[vehicleId] : 0) + (distance || 0);
  //       setLastRideDistanceCumulative({ ...lastDistance, [vehicleId]: newValue });
  //       return newValue;
  //     }
  //     return distance;
  //   },
  //   [isRidePlayerOpen, setLastRideDistanceCumulative]
  // );

  // const handleOnBluecoinPositionBulkMessageReceived = React.useCallback(
  //   (bulkMessage: ISocketServerBulkMessage<BluecoinPositionData>) => {
  //     const bluecoinsDataToUpdate = {};
  //     bulkMessage.data.forEach((message: BluecoinPositionData) => {
  //       bluecoinsDataToUpdate[message.bluecoinId] = message;
  //     });
  //     setBluecoins((oldState: BluecoinsData) => {
  //       const newBluecoinsData: BluecoinsData = {};
  //       Object.keys(bluecoinsDataToUpdate).map((bluecoinId: string) => {
  //         const bluecoinData: BluecoinData = oldState[Number(bluecoinId)];
  //         const bluecoinMessageData: BluecoinPositionData =
  //           bluecoinsDataToUpdate[Number(bluecoinId)];

  //         const bluecoinAttributes = { ...(bluecoinData.attributes || {}) };

  //         if (bluecoinMessageData.attributes.bat) {
  //           bluecoinAttributes.bat = bluecoinMessageData.attributes.bat;
  //           // Object.assign(bluecoinAttributes, { bat: bluecoinMessageData.attributes.bat });
  //         }
  //         if (bluecoinMessageData.attributes.hum) {
  //           bluecoinAttributes.hum = bluecoinMessageData.attributes.hum;
  //           // Object.assign(bluecoinAttributes, { hum: bluecoinMessageData.attributes.hum });
  //         }
  //         if (bluecoinMessageData.attributes.lb) {
  //           bluecoinAttributes.lb = bluecoinMessageData.attributes.lb;
  //           // Object.assign(bluecoinAttributes, { lb: bluecoinMessageData.attributes.lb });
  //         }
  //         if (bluecoinMessageData.attributes.mag) {
  //           bluecoinAttributes.mag = bluecoinMessageData.attributes.mag;
  //           // Object.assign(bluecoinAttributes, { mag: bluecoinMessageData.attributes.mag });
  //         }
  //         if (bluecoinMessageData.attributes.mag_cnt) {
  //           bluecoinAttributes.mag_cnt = bluecoinMessageData.attributes.mag_cnt;
  //           // Object.assign(bluecoinAttributes, { mag_cnt: bluecoinMessageData.attributes.mag_cnt });
  //         }
  //         if (bluecoinMessageData.attributes.mv) {
  //           bluecoinAttributes.mv = bluecoinMessageData.attributes.mv;
  //           // Object.assign(bluecoinAttributes, { mv: bluecoinMessageData.attributes.mv });
  //         }
  //         if (bluecoinMessageData.attributes.mv_cnt) {
  //           bluecoinAttributes.bat = bluecoinMessageData.attributes.bat;
  //           // Object.assign(bluecoinAttributes, { mv_cnt: bluecoinMessageData.attributes.mv_cnt });
  //         }
  //         if (bluecoinMessageData.attributes.mvax) {
  //           bluecoinAttributes.mvax = bluecoinMessageData.attributes.mvax;
  //           // Object.assign(bluecoinAttributes, { mvax: bluecoinMessageData.attributes.mvax });
  //         }
  //         if (bluecoinMessageData.attributes.mvay) {
  //           bluecoinAttributes.mvay = bluecoinMessageData.attributes.mvay;
  //           // Object.assign(bluecoinAttributes, { mvay: bluecoinMessageData.attributes.mvay });
  //         }
  //         if (bluecoinMessageData.attributes.mvaz) {
  //           bluecoinAttributes.mvaz = bluecoinMessageData.attributes.mvaz;
  //           // Object.assign(bluecoinAttributes, { mvaz: bluecoinMessageData.attributes.mvaz });
  //         }
  //         if (bluecoinMessageData.attributes.mvc) {
  //           bluecoinAttributes.mvc = bluecoinMessageData.attributes.mvc;
  //           // Object.assign(bluecoinAttributes, { mvc: bluecoinMessageData.attributes.mvc });
  //         }
  //         if (bluecoinMessageData.attributes.pitch) {
  //           bluecoinAttributes.pitch = bluecoinMessageData.attributes.pitch;
  //           // Object.assign(bluecoinAttributes, { pitch: bluecoinMessageData.attributes.pitch });
  //         }
  //         if (bluecoinMessageData.attributes.roll) {
  //           bluecoinAttributes.roll = bluecoinMessageData.attributes.roll;
  //           // Object.assign(bluecoinAttributes, { roll: bluecoinMessageData.attributes.roll });
  //         }
  //         if (bluecoinMessageData.attributes.rssi) {
  //           bluecoinAttributes.rssi = bluecoinMessageData.attributes.rssi;
  //           // Object.assign(bluecoinAttributes, { rssi: bluecoinMessageData.attributes.rssi });
  //         }
  //         if (bluecoinMessageData.attributes.tmp) {
  //           bluecoinAttributes.tmp = bluecoinMessageData.attributes.tmp;
  //           // Object.assign(bluecoinAttributes, { tmp: bluecoinMessageData.attributes.tmp });
  //         }

  //         const newBluecoinData: BluecoinData = {
  //           ...bluecoinData,
  //           // name: bluecoinMessageData.assignedTo?.name
  //           //   ? bluecoinMessageData.assignedTo.name
  //           //   : bluecoinData.name,
  //           position: {
  //             address: bluecoinMessageData.position.address.Valid
  //               ? bluecoinMessageData.position.address.String
  //               : null,
  //             latitude: bluecoinMessageData.position.latitude,
  //             longitude: bluecoinMessageData.position.longitude,
  //             angle: bluecoinMessageData.position.angle.Valid
  //               ? bluecoinMessageData.position.angle.V
  //               : 0,
  //             timestamp: bluecoinMessageData.position.timestamp,
  //           },
  //           assignedTo: bluecoinMessageData.assignedTo
  //             ? {
  //                 vehicleId: bluecoinMessageData.assignedTo.vehicleId,
  //                 startTs: bluecoinMessageData.assignedTo.startTs,
  //                 name: bluecoinMessageData.assignedTo.name,
  //                 endTs: bluecoinMessageData.assignedTo.endTs.Valid
  //                   ? bluecoinMessageData.assignedTo.endTs.Time
  //                   : undefined,
  //               }
  //             : null,
  //           // owner: {
  //           //   customerId: bluecoinMessageData.customerId,
  //           //   label: bluecoinData.owner.label,
  //           // },
  //           attached: bluecoinMessageData.status === IS_ACTIVE,
  //           attributes: bluecoinAttributes,
  //         };
  //         newBluecoinsData[Number(bluecoinId)] = newBluecoinData;
  //       });
  //       const newState = {
  //         ...oldState,
  //         ...newBluecoinsData,
  //       };
  //       return newState;
  //     });
  //   },
  //   [setBluecoins]
  // );

  /**
   * Handle "vehicle_position_bulk" message
   *
   * Contains 1 or more "vehicle_position" events
   */
  // const handleOnVehiclePositionBulkMessageReceived = React.useCallback(
  //   (bulkMessage: ISocketServerBulkMessage<IEventVehiclePositionPayload>) => {
  //     const vehiclesData: IVehicleDataMinimal = {};
  //     const vehicles: IVehicleMinimal = {};
  //     const vehicleOnlinePanels = {};
  //     const ecoDrives = {};

  //     bulkMessage.data.forEach((message: IEventVehiclePositionPayload) => {
  //       const { drivingMode, ignition, latitude, longitude, status, timestamp, vehicleId } =
  //         message;
  //       const {
  //         address,
  //         altitude,
  //         angle,
  //         announcements,
  //         battery,
  //         ecoDriveScore,
  //         engineState,
  //         engineTemperature,
  //         fuelLevelLiters,
  //         fuelLevelPercentage,
  //         onCng,
  //         onCruise,
  //         onLpg,
  //         pto,
  //         remoteLock,
  //         rpm,
  //         satellites,
  //         speed,
  //         throttle,
  //         webasto,
  //         mileage,
  //         electric,
  //       } = message.onlinePanel;
  //       const distanceCumulative = getDistanceCumulative(message, lastRideDistanceCumulative);

  //       const vehiclePosition: IVehiclePosition = {
  //         latitude,
  //         longitude,
  //         address,
  //         altitude,
  //         angle,
  //         timestamp,
  //         distanceCumulative,
  //       };

  //       const lastRide: IVehicleLastRide = {
  //         start: {
  //           timestamp: '',
  //           latitude: 0.0,
  //           longitude: 0.0,
  //           address: '',
  //         },
  //         end: {
  //           timestamp: '',
  //           latitude: 0.0,
  //           longitude: 0.0,
  //           address: '',
  //         },
  //       };

  //       const vehicleData: IVehicleData = {
  //         vehicleId,
  //         status: status as TVehicleStatus,
  //         lastRide: lastRide as IVehicleLastRide,
  //         position: vehiclePosition,
  //         drivingMode,
  //       };

  //       vehiclesData[vehicleId] = vehicleData;

  //       const vehicle = {
  //         id: vehicleId,
  //         drivingMode,
  //         status: status as TVehicleStatus,
  //       };

  //       vehicles[vehicleId] = vehicle;

  //       // Tato chujovina je tu kvoli BE, lebo raz to poslu ako pole objektov a raz ako objekt objektov.
  //       let newAnnouncements: IAnnouncement[] = [];
  //       if (Array.isArray(announcements)) {
  //         newAnnouncements = announcements.map((announcement) => {
  //           const stringTranslation =
  //             announcement.eventType && announcement.eventType === IS_BINARY
  //               ? `${f({ id: `admin.events.code.${announcement.title}` })} ${
  //                   announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
  //                 }`
  //               : announcement.title;
  //           const newAnnoncement = {
  //             ...announcement,
  //             title: stringTranslation,
  //           };
  //           return newAnnoncement;
  //         });
  //       }

  //       const newVehicleOnlinePanel: IVehicleOnlinePanelState = {
  //         announcements: newAnnouncements,
  //         vehicleId,
  //         altitude,
  //         battery,
  //         engineTemperature,
  //         engineState,
  //         ecoDriveScore: ecoDriveScore !== null ? Math.round(ecoDriveScore * 10) / 10 : null,
  //         fuelLevelLiters,
  //         fuelLevelPercentage,
  //         rpm,
  //         satellites,
  //         speed,
  //         throttle,
  //         timestamp,
  //         latitude,
  //         longitude,
  //         address,
  //         onCng,
  //         onCruise,
  //         onLpg,
  //         remoteLock,
  //         webasto,
  //         pto,
  //         ignition,
  //         code: message.code,
  //         mileage,
  //         electric,
  //       };

  //       vehicleOnlinePanels[vehicleId] = newVehicleOnlinePanel;

  //       if (newVehicleOnlinePanel.ecoDriveScore) {
  //         let date = '';
  //         if (newVehicleOnlinePanel.timestamp) {
  //           date = newVehicleOnlinePanel.timestamp;
  //         }
  //         const data = {
  //           distance: distanceCumulative ? `${distanceCumulative} km` : null,
  //           ecoDriveScore: newVehicleOnlinePanel.ecoDriveScore,
  //           timestamp: newVehicleOnlinePanel.timestamp ? newVehicleOnlinePanel.timestamp : '',
  //           code: newVehicleOnlinePanel.code,
  //         };

  //         if (
  //           !(
  //             ecoDrive[String(newVehicleOnlinePanel.vehicleId)] &&
  //             ecoDrive[String(newVehicleOnlinePanel.vehicleId)].timestamp === date
  //           )
  //         ) {
  //           ecoDrives[vehicleId] = data;
  //         }
  //       }
  //     });

  //     setVehiclesData((oldState: IVehiclesData) => {
  //       const newVehiclesData: IVehiclesData = {};
  //       Object.keys(vehiclesData).forEach((vehicleId: string) => {
  //         if (oldState[vehicleId]) {
  //           let lastRideId: string | null = null;
  //           if (
  //             vehiclesData[vehicleId].status === 'STOP' &&
  //             vehiclesData[vehicleId].rideId === null &&
  //             oldState[vehicleId].lastRide
  //           ) {
  //             lastRideId = oldState[vehicleId].lastRide?.id || null;
  //           }
  //           let newVehicleDataPosition = null;
  //           if (oldState[vehicleId] && oldState[vehicleId].position) {
  //             newVehicleDataPosition = {
  //               ...oldState[vehicleId].position,
  //               lastRideId: lastRideId || '',
  //             };
  //           }

  //           let newLastRide = null;
  //           if (oldState[vehicleId] && oldState[vehicleId].lastRide) {
  //             newLastRide = {
  //               ...oldState[vehicleId].lastRide,
  //               ...vehiclesData[vehicleId].lastRide,
  //               lastRideId: lastRideId || '',
  //             };
  //           }

  //           const newVehicleData = {
  //             ...vehiclesData[vehicleId],
  //             position: newVehicleDataPosition,
  //             lastRide: newLastRide,
  //           };
  //           const newVehiclesData2 = {
  //             ...vehiclesData,
  //             ...newVehicleData,
  //           };
  //           newVehiclesData[vehicleId] = {
  //             ...oldState[vehicleId],
  //             ...newVehiclesData2[vehicleId],
  //           };
  //         } else {
  //           console.warn(`State not exists for vehicle ${vehicleId}.`);
  //         }
  //       });

  //       const newState = {
  //         ...oldState,
  //         ...newVehiclesData,
  //       };
  //       return newState;
  //     });

  //     setVehicles((oldState: IVehicles) => {
  //       const newVehicles: IVehicles = {};
  //       Object.keys(oldState).forEach((vehicleId: string) => {
  //         newVehicles[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicles[vehicleId],
  //         };
  //       });

  //       const newState = {
  //         ...oldState,
  //         ...newVehicles,
  //       };
  //       return newState;
  //     });

  //     setOnlinePanel((oldState: IVehicleOnlinePanels) => {
  //       const newVehicleOnlinePanels: IVehicleOnlinePanels = {};
  //       Object.keys(vehicleOnlinePanels).forEach((vehicleId: string) => {
  //         newVehicleOnlinePanels[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicleOnlinePanels[vehicleId],
  //         };
  //       });
  //       const newState: IVehicleOnlinePanels = {
  //         ...oldState,
  //         ...newVehicleOnlinePanels,
  //       };
  //       return newState;
  //     });

  //     setEcoDriveData((oldState: { [name: string]: IEcoDriveState }) => {
  //       const newEcoDriveData: { [name: string]: IEcoDriveState } = {};
  //       Object.keys(vehicleOnlinePanels).forEach((vehicleId: string) => {
  //         newEcoDriveData[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicleOnlinePanels[vehicleId],
  //         };
  //       });

  //       const newState: { [name: string]: IEcoDriveState } = {
  //         ...oldState,
  //         ...newEcoDriveData,
  //       };
  //       return newState;
  //     });
  //   },
  //   [
  //     ecoDrive,
  //     f,
  //     getDistanceCumulative,
  //     lastRideDistanceCumulative,
  //     setEcoDriveData,
  //     setOnlinePanel,
  //     setVehicles,
  //     setVehiclesData,
  //   ]
  // );

  /**
   * Handle "vehicle_position" event
   *
   * When last ride player is open - SocketServer is sending this event for selected vehicle.
   * It ignores bulking
   */
  // const handleOnVehiclePositionMessageReceived = React.useCallback(
  //   (message: IEventVehiclePositionPayload) => {
  //     const { drivingMode, ignition, latitude, longitude, status, timestamp, vehicleId } = message;

  //     const {
  //       address,
  //       altitude,
  //       angle,
  //       announcements,
  //       battery,
  //       ecoDriveScore,
  //       engineState,
  //       engineTemperature,
  //       fuelLevelLiters,
  //       fuelLevelPercentage,
  //       onCng,
  //       onCruise,
  //       onLpg,
  //       pto,
  //       remoteLock,
  //       rpm,
  //       satellites,
  //       speed,
  //       throttle,
  //       webasto,
  //       mileage,
  //       electric,
  //     } = message.onlinePanel;

  //     const distanceCumulative = getDistanceCumulative(message, lastRideDistanceCumulative);

  //     setVehiclesData((oldState: IVehiclesData) => {
  //       const vehiclePosition: IVehiclePosition = {
  //         latitude,
  //         longitude,
  //         address,
  //         altitude,
  //         angle,
  //         distanceCumulative,
  //         timestamp,
  //       };

  //       const lastRide: IVehicleLastRide = {
  //         start: {
  //           timestamp: '',
  //           latitude: 0.0,
  //           longitude: 0.0,
  //           address: '',
  //         },
  //         end: {
  //           timestamp: '',
  //           latitude: 0.0,
  //           longitude: 0.0,
  //           address: '',
  //         },
  //       };
  //       const vehicleData: IVehicleData = {
  //         vehicleId,
  //         status: status as TVehicleStatus,
  //         lastRide: lastRide as IVehicleLastRide,
  //         position: vehiclePosition,
  //         drivingMode,
  //       };

  //       const data: IVehicleData = {
  //         ...oldState[vehicleId],
  //         ...vehicleData,
  //       };
  //       return {
  //         ...oldState,
  //         [vehicleId]: data,
  //       };
  //     });

  //     setVehicles((oldState: IVehicles) => {
  //       const vehicle: IVehicle = {
  //         ...oldState[vehicleId],
  //         id: vehicleId,
  //         drivingMode,
  //         status: status as TVehicleStatus,
  //       };
  //       return {
  //         ...oldState,
  //         [vehicleId]: vehicle,
  //       };
  //     });

  //     // Tato vec je tu kvoli BE, lebo raz to poslu ako pole objektov a raz ako objekt objektov.
  //     let newAnnouncements: IAnnouncement[] = [];
  //     if (Array.isArray(announcements)) {
  //       newAnnouncements = announcements.map((announcement) => {
  //         const stringTranslation =
  //           announcement.eventType && announcement.eventType === IS_BINARY
  //             ? `${f({ id: `admin.events.code.${announcement.title}` })} ${
  //                 announcement.duration ? `(${convertDuration(announcement.duration)})` : ''
  //               }`
  //             : announcement.title;
  //         const newAnnoncement = {
  //           ...announcement,
  //           title: stringTranslation,
  //         };
  //         return newAnnoncement;
  //       });
  //     }

  //     const newVehicleOnlinePanel: IVehicleOnlinePanelState = {
  //       announcements: newAnnouncements,
  //       vehicleId,
  //       altitude,
  //       battery,
  //       engineTemperature,
  //       engineState,
  //       onCruise,
  //       onCng,
  //       onLpg,
  //       remoteLock,
  //       pto,
  //       ecoDriveScore: ecoDriveScore !== null ? Math.round(ecoDriveScore * 10) / 10 : null,
  //       fuelLevelLiters,
  //       fuelLevelPercentage,
  //       rpm,
  //       satellites,
  //       speed,
  //       throttle,
  //       timestamp,
  //       latitude,
  //       longitude,
  //       address,
  //       webasto,
  //       ignition,
  //       code: message.code,
  //       mileage,
  //       electric,
  //     };
  //     setOnlinePanel((oldState: IVehicleOnlinePanels) => {
  //       const newState: IVehicleOnlinePanels = {
  //         ...oldState,
  //         [vehicleId]: newVehicleOnlinePanel,
  //       };
  //       return newState;
  //     });

  //     if (newVehicleOnlinePanel.ecoDriveScore) {
  //       let date = '';
  //       if (newVehicleOnlinePanel.timestamp) {
  //         date = newVehicleOnlinePanel.timestamp;
  //       }
  //       const data = {
  //         address: newVehicleOnlinePanel.address,
  //         latitude: newVehicleOnlinePanel.latitude,
  //         longitude: newVehicleOnlinePanel.longitude,
  //         distance: distanceCumulative ? `${distanceCumulative} km` : null,
  //         ecoDriveScore: newVehicleOnlinePanel.ecoDriveScore,
  //         timestamp: newVehicleOnlinePanel.timestamp ? newVehicleOnlinePanel.timestamp : '',
  //         code: newVehicleOnlinePanel.code,
  //       };

  //       if (
  //         !(
  //           ecoDrive[String(newVehicleOnlinePanel.vehicleId)] &&
  //           ecoDrive[String(newVehicleOnlinePanel.vehicleId)].timestamp === date
  //         )
  //       ) {
  //         setEcoDriveData((state: IGraphData) =>
  //           updateEcoDriveState(state, { [String(newVehicleOnlinePanel.vehicleId)]: data })
  //         );
  //       }
  //     }
  //   },
  //   [
  //     ecoDrive,
  //     f,
  //     getDistanceCumulative,
  //     lastRideDistanceCumulative,
  //     setEcoDriveData,
  //     setOnlinePanel,
  //     setVehicles,
  //     setVehiclesData,
  //   ]
  // );

  // const handleOnEcoDriveViolationMessageRecieve = React.useCallback(
  //   (ecoDriveViolationData: IEventRideEdViolationPayload) => {
  //     if (ecoDriveViolationData.violation) {
  //       let date = '';
  //       if (ecoDriveViolationData.violation.timestamp) {
  //         date = ecoDriveViolationData.violation.timestamp;
  //       }
  //       const data: IEcoDriveState = {
  //         distance: String(ecoDriveViolationData.violation.distance),
  //         // ecoDriveScore: ecoDriveViolationData.score / 100,
  //         ecoDriveScore: ecoDriveViolationData.score,
  //         code: ecoDriveViolationData.code,
  //         timestamp: ecoDriveViolationData.violation.timestamp
  //           ? ecoDriveViolationData.violation.timestamp
  //           : '',
  //         duration: ecoDriveViolationData.violation.duration,
  //         link: ecoDriveViolationData.violation.links.map,
  //         map: ecoDriveViolationData.violation.map,
  //         maxValue: String(ecoDriveViolationData.violation.maxValue.value),
  //         tooltip: ecoDriveViolationData.violation.maxValue.tooltip,
  //         type: ecoDriveViolationData.violation.type,
  //         address: ecoDriveViolationData.violation.address || ecoDriveViolationData.violation.map,
  //       };

  //       if (
  //         !(
  //           ecoDrive[`violation-${ecoDriveViolationData.vehicleId}`] &&
  //           ecoDrive[`violation-${ecoDriveViolationData.vehicleId}`].timestamp === date
  //         )
  //       ) {
  //         setEcoDriveData((state: IGraphData) =>
  //           updateEcoDriveState(state, { [`violation-${ecoDriveViolationData.vehicleId}`]: data })
  //         );
  //       }
  //     }
  //   },
  //   [ecoDrive, setEcoDriveData]
  // );

  // const handleOnEcoDriveViolationBulkMessageRecieve = React.useCallback(
  //   (bulkMessage: ISocketServerBulkMessage<IEventRideEdViolationPayload>) => {
  //     const ecoDrives = {};

  //     bulkMessage.data.forEach((message: IEventRideEdViolationPayload) => {
  //       if (message.violation) {
  //         let date = '';
  //         if (message.violation.timestamp) {
  //           date = message.violation.timestamp;
  //         }
  //         const data: IEcoDriveState = {
  //           distance: String(message.violation.distance),
  //           // ecoDriveScore: message.score / 100,
  //           ecoDriveScore: message.score,
  //           code: message.code,
  //           timestamp: message.violation.timestamp ? message.violation.timestamp : '',
  //           duration: message.violation.duration,
  //           link: `https://maps.google.com/?q=${message.violation.map}`, // message.violation.links.map,
  //           map: message.violation.map,
  //           maxValue: String(message.violation.maxValue.value),
  //           tooltip: message.violation.maxValue.tooltip,
  //           type: message.violation.type,
  //           address: message.violation.address || message.violation.map,
  //         };

  //         if (
  //           !(
  //             ecoDrive[`violation-${message.vehicleId}`] &&
  //             ecoDrive[`violation-${message.vehicleId}`].timestamp === date
  //           )
  //         ) {
  //           ecoDrives[`violation-${message.vehicleId}`] = data;
  //         }
  //       }
  //     });

  //     if (Object.keys(ecoDrives).length > 0) {
  //       setEcoDriveData((oldState: IGraphData) => {
  //         const newState = {
  //           ...oldState,
  //           ...ecoDrives,
  //         };
  //         return newState;
  //       });
  //     }
  //   },
  //   [ecoDrive, setEcoDriveData]
  // );

  // const handleDefaultTemplateChange = React.useCallback(
  //   (defaultTemplateChange: IUnitDataProcessorDefaultTemplateChange) => {
  //     setDefaultOnlinePanelTemplate(defaultTemplateChange);
  //   },
  //   [setDefaultOnlinePanelTemplate]
  // );

  // const handleDefaultTemplateChangeBulk = React.useCallback(
  //   (bulkMessage: ISocketServerBulkMessage<IUnitDataProcessorDefaultTemplateChange>) => {
  //     bulkMessage.data.forEach((message: IUnitDataProcessorDefaultTemplateChange) => {
  //       setDefaultOnlinePanelTemplate(message);
  //     });
  //   },
  //   [setDefaultOnlinePanelTemplate]
  // );

  // const handleOnNavigatorDataBulkMessageRecieved = React.useCallback(
  //   (bulkMessage: ISocketServerBulkMessage<IUnitDataProcessorNavigatorData>) => {
  //     const vehiclesData: { [vehicleId: number]: IVehicleData | IVehicleDataMinimal } = {};
  //     let vehicles: IVehicleMinimal = {};
  //     bulkMessage.data.forEach((message: IUnitDataProcessorNavigatorData) => {
  //       const { vehicleId, timestamp, status } = message;

  //       vehiclesData[vehicleId] = {
  //         vehicleId,
  //         status: status as TVehicleStatus,
  //         drivingMode: message.drivingMode,
  //       };

  //       const vehicle: IVehicleMinimal = {
  //         [vehicleId]: {
  //           id: vehicleId,
  //           drivingMode: message.drivingMode,
  //           status: status as TVehicleStatus,
  //         },
  //       };

  //       vehicles = {
  //         ...vehicles,
  //         ...vehicle,
  //       };
  //     });

  //     setVehiclesData((oldState: IVehiclesData) => {
  //       const newVehiclesData: IVehiclesData = {};
  //       Object.keys(vehicles).forEach((vehicleId: string) => {
  //         if (oldState[vehicleId]) {
  //           const newVehicleDataPosition = {
  //             ...oldState[vehicleId].position,
  //             distanceCumulative:
  //               oldState[vehicleId] &&
  //               oldState[vehicleId].position &&
  //               oldState[vehicleId].position?.distanceCumulative
  //                 ? oldState[vehicleId].position?.distanceCumulative || null
  //                 : null,
  //           };
  //           const newVehicleData = {
  //             ...vehiclesData[vehicleId],
  //             position: newVehicleDataPosition,
  //           };
  //           const newVehiclesData2 = {
  //             ...vehiclesData,
  //             ...newVehicleData,
  //           };
  //           newVehiclesData[vehicleId] = {
  //             ...oldState[vehicleId],
  //             ...newVehiclesData2[vehicleId],
  //           };
  //         }
  //       });

  //       const newState = {
  //         ...oldState,
  //         ...newVehiclesData,
  //       };
  //       return newState;
  //     });

  //     setVehicles((oldState: IVehicles) => {
  //       const newVehicles: IVehicles = {};
  //       Object.keys(oldState).forEach((vehicleId: string) => {
  //         newVehicles[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicles[vehicleId],
  //         };
  //       });

  //       const newState = {
  //         ...oldState,
  //         ...newVehicles,
  //       };
  //       return newState;
  //     });
  //   },
  //   [setVehicles, setVehiclesData]
  // );

  // const handleNavigatorDataMessageRecieved = React.useCallback(
  //   (navigatorData: IUnitDataProcessorNavigatorData) => {
  //     const { vehicleId, lastPublicData, timestamp, status, driverId, driverName } = navigatorData;
  //     const { vehicleId, timestamp, status } = navigatorData;

  //     setVehiclesData((oldState: IVehiclesData) => {
  //       const data = {
  //         ...oldState[vehicleId],
  //         vehicleId,
  //         status: status as TVehicleStatus,
  //         drivingMode: navigatorData.drivingMode,
  //       };
  //       return {
  //         ...oldState,
  //         [vehicleId]: data,
  //       };
  //     });

  //     setVehicles((oldState: IVehicles) => {
  //       const vehicle: IVehicle = {
  //         ...oldState[vehicleId],
  //         id: vehicleId,
  //         drivingMode: navigatorData.drivingMode,
  //         status: status as TVehicleStatus,
  //       };
  //       return {
  //         ...oldState,
  //         [vehicleId]: vehicle,
  //       };
  //     });
  //   },
  //   [setVehicles, setVehiclesData]
  // );

  // const handleVehiclesNewData = React.useCallback(
  //   async (ids: number[]) => {
  //     const responseVehicle = await NavigatorService.getVehiclesbyIds(ids);
  //     if (responseVehicle) {
  //       setVehicles((oldState: IVehicles) => {
  //         return { ...oldState, ...VehicleService.vehicleArrayToObject(responseVehicle.vehicles) };
  //       });
  //       setVehiclesData((oldState: IVehiclesData) => {
  //         return {
  //           ...oldState,
  //           ...VehicleService.vehicleDataArrayToObject(responseVehicle.vehiclesData),
  //         };
  //       });
  //       setOnlinePanel((oldState) => {
  //         return {
  //           ...oldState,
  //           ...VehicleService.vehicleOnlinePanelArrayToObject(responseVehicle.onlinePanelData),
  //         };
  //       });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  // const handleDataChangeBulk = React.useCallback(
  //   async (bulkMessage: ISocketServerBulkMessage<IEventDataChangePayload>) => {
  //     const vehicles: IVehicles = {};
  //     const messageVehicles: IMessageVehicles = {};
  //     const driverNamesToBeUpdated = {};
  //     const vehiclesToByRequested: number[] = [];
  //     bulkMessage.data.forEach((message: IEventDataChangePayload) => {
  //       messageVehicles[message.vehicleId] = message;
  //     });

  //     setVehicles((oldState: IVehicles) => {
  //       const newVehicles: IVehicles = {};
  //       Object.keys(oldState).forEach((vehicleId: string) => {
  //         newVehicles[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicles[vehicleId],
  //         };

  //         if (messageVehicles[vehicleId] && newVehicles[vehicleId]) {
  //           // If reason is "rideChanged" we want new data from vehicles endpoint
  //           if (
  //             !messageVehicles[vehicleId].reason ||
  //             (messageVehicles[vehicleId].reason &&
  //               messageVehicles[vehicleId].reason === 'rideModeChange')
  //           ) {
  //             vehiclesToByRequested.push(messageVehicles[vehicleId].vehicleId);
  //           }

  //           // Update driverId when differs from previous state
  //           if (
  //             messageVehicles[vehicleId].driverId &&
  //             messageVehicles[vehicleId].driverId !== oldState[vehicleId].driver.id
  //           ) {
  //             // newVehicles[vehicleId].driver.id = messageVehicles[vehicleId].driverId;
  //             driverNamesToBeUpdated[vehicleId] = messageVehicles[vehicleId].driverId;
  //             // @ts-checktodo make a request for driver name
  //           }

  //           if (
  //             messageVehicles[vehicleId].driverId === 0 ||
  //             messageVehicles[vehicleId].driverId === null
  //           ) {
  //             if (messageVehicles[vehicleId].driverId !== oldState[vehicleId].driver.id) {
  //               const newDriver: IVehicleDriver = {
  //                 name: null,
  //                 id: null,
  //               };
  //               newVehicles[vehicleId].driver = newDriver;
  //             }
  //           }

  //           // Update drivingMode when differs from previous state
  //           if (
  //             messageVehicles[vehicleId].drivingMode &&
  //             messageVehicles[vehicleId].drivingMode !== oldState[vehicleId].drivingMode
  //           ) {
  //             newVehicles[vehicleId].drivingMode = messageVehicles[vehicleId].drivingMode;
  //           }

  //           // Update licenceNumber when differs from previous state
  //           if (
  //             messageVehicles[vehicleId].licenceNumber &&
  //             messageVehicles[vehicleId].licenceNumber !== oldState[vehicleId].licenceNumber
  //           ) {
  //             newVehicles[vehicleId].licenceNumber = messageVehicles[vehicleId].licenceNumber;
  //           }

  //           // Update vehicle name when differs from previous state
  //           if (
  //             messageVehicles[vehicleId].name &&
  //             messageVehicles[vehicleId].name !== oldState[vehicleId].name
  //           ) {
  //             newVehicles[vehicleId].name = messageVehicles[vehicleId].name;
  //           }

  //           // Update vehicle type id when differs from previous state
  //           if (
  //             messageVehicles[vehicleId].vehicleTypeId &&
  //             messageVehicles[vehicleId].vehicleTypeId !== oldState[vehicleId].vehicleTypeId
  //           ) {
  //             newVehicles[vehicleId].vehicleTypeId = messageVehicles[vehicleId].vehicleTypeId;
  //           }
  //         }
  //       });

  //       const newState = {
  //         ...oldState,
  //         ...newVehicles,
  //       };

  //       return newState;
  //     });

  //     // drivingMode change is in vehiclesData -> TODO: resolve vehicles/vehiclesData discrepancies
  //     setVehiclesData((oldState: IVehiclesData) => {
  //       const newVehiclesData: IVehiclesData = {};
  //       Object.keys(oldState).forEach((vehicleId: string) => {
  //         newVehiclesData[vehicleId] = {
  //           ...oldState[vehicleId],
  //           ...vehicles[vehicleId],
  //         };

  //         if (messageVehicles[vehicleId] && newVehiclesData[vehicleId]) {
  //           if (
  //             messageVehicles[vehicleId].drivingMode &&
  //             messageVehicles[vehicleId].drivingMode !== oldState[vehicleId].drivingMode
  //           ) {
  //             newVehiclesData[vehicleId].drivingMode = messageVehicles[vehicleId].drivingMode;
  //           }
  //         }
  //       });
  //       const newState = {
  //         ...oldState,
  //         ...newVehiclesData,
  //       };
  //       return newState;
  //     });

  //     if (Object.keys(vehiclesToByRequested).length > 0) {
  //       handleVehiclesNewData(vehiclesToByRequested);
  //     }

  //     if (Object.keys(driverNamesToBeUpdated).length > 0) {
  //       Object.keys(driverNamesToBeUpdated).map(async (vehicleId: string) => {
  //         const driverId = driverNamesToBeUpdated[Number(vehicleId)];
  //         const driver = await NavigatorService.getDriver(driverId);

  //         if (driver) {
  //           setVehicles((oldState: IVehicles) => {
  //             const newDriver = {
  //               id: driver.id,
  //               name: `${driver.firstName} ${driver.lastName}`,
  //             };
  //             const newVehicle = {
  //               ...oldState[Number(vehicleId)],
  //               driver: newDriver,
  //             };
  //             const newVehicles = {
  //               ...oldState,
  //               [Number(vehicleId)]: newVehicle,
  //             };
  //             return newVehicles;
  //           });
  //         }
  //       });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [setVehicles, setVehiclesData]
  // );

  const handleSubscribeToChannels = React.useCallback((channels: string[]) => {
    const data = {
      event: EVENT_JOIN,
      payload: channels,
      userId: UserService.getUserId(),
    };
    SocketConnectionWorker.postMessage(data);
  }, []);

  const joinInitialChannels = () => {
    const channelsToJoin: string[] = [];

    // Join 'system' channel for logged user
    channelsToJoin.push(`system:${UserService.getUserId()}`);

    // Join 'navigator' channel for selected customers
    setChannelsToBeJoined(channelsToJoin);
  };

  React.useEffect(() => {
    const jwt = JWTService.getSocketServerToken();
    if (jwt) {
      handleConnect(jwt);
      joinInitialChannels();
    }

    return () => {
      if (connectionStatus) {
        handleOnConnectionClose();
        SocketConnectionWorker.terminate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGdpr = React.useCallback(
    async (vehicle: IVehicle): Promise<boolean> => {
      const driverId = localStorage.getItem('driverId');

      if (!vehicle.driver) {
        console.warn(`Vehicle ${vehicle.id} has no driver.`, vehicle);
        return true;
      }

      if (driverId === String(vehicle.driver.id)) {
        return true;
      }
      const profiles = await localforage.getItem<Record<string, any>[]>('gdprSettings');
      if (Array.isArray(profiles) && profiles.length > 0) {
        const selectedProfiles = profiles.filter((i: any) =>
          selectedCustomers.includes(i.customerId)
        );

        if (selectedProfiles && selectedProfiles.length > 0) {
          const filteredProfile = selectedProfiles.find(
            (i: any) => vehicle.customerId === i.customerId
          );

          const isAllowed = false;

          if (filteredProfile) {
            if (
              filteredProfile &&
              filteredProfile.authMap &&
              filteredProfile.authMap[0] &&
              filteredProfile.authMap[0].enabled
            ) {
              return filteredProfile.authMap[0].enabled;
            }
            return false;
          }
          return isAllowed;
        }
        return false;
      }
      return false;
    },
    [selectedCustomers]
  );

  React.useEffect(() => {
    const filterVehicle: number[] = [];
    Object.keys(navigatorVehicles).forEach(async (vehicleId: string) => {
      const vehicle = navigatorVehicles[Number(vehicleId)];
      if (
        (vehicle && vehicle.drivingMode === 'business' && selectedVehicles.includes(vehicle.id)) ||
        (vehicle &&
          vehicle.drivingMode === 'private' &&
          selectedVehicles.includes(vehicle.id) &&
          (await handleGdpr(vehicle)))
      ) {
        filterVehicle.push(vehicle.id);
      }
    });

    async function updateVehicleGdprData() {
      const gdprPromises = selectedVehicles.map(async (vehicleId) => {
        const vehicle = navigatorVehicles[vehicleId];
        if (vehicle) {
          const isGdprAllowed = await handleGdpr(vehicle);
          return { id: vehicle.id, gdprAllowed: isGdprAllowed };
        }
        return null;
      });

      const gdprArray = (await Promise.all(gdprPromises)).filter(Boolean);
      const newState = {};

      gdprArray.forEach((item) => {
        if (item) newState[item.id] = item.gdprAllowed;
      });

      setIsVehicleGdprDataAllowed(newState);
    }
    updateVehicleGdprData();

    setFilteredSelectedVehicle(filterVehicle || []);
  }, [handleGdpr, navigatorVehicles, selectedVehicles, setIsVehicleGdprDataAllowed]);

  // Subcribe to channels
  React.useEffect(() => {
    const joinChannels = [...channelsToBeJoined];
    handleSubscribeToChannels(joinChannels);
  }, [channelsToBeJoined, handleSubscribeToChannels]);

  React.useEffect(() => {
    SocketConnectionWorker.onmessage = async (message: MessageEvent<IWebSocketEvent>) => {
      if (message && message.data && message.data.event) {
        if (message.data.event === EVENT_LOGOUT) {
          handleOnLogoutMessageReceived(message.data.payload as IMessageLogout);
        }
        if (message.data.event === EVENT_CONNECTION_OPEN) {
          handleOnConnectionOpen();
        }
        if (message.data.event === EVENT_CONNECTION_CLOSE) {
          handleOnConnectionClose();
        }
        if (message.data.event === EVENT_CONNECTION_ERROR) {
          handleOnConnectionError(message.data.payload as string);
        }
        if (message.data.event === EVENT_RIDE_CHANGED) {
          // handleRideChanged(message.data.payload as number);
        }
        if (message.data.event === EVENT_REPORT_CREATED) {
          // handleOnReportCreatedMessageReceived(message.data.payload as IMessageReportCreated);
        }
        if (message.data.event === EVENT_USER_TEMPLATE_CHANGE) {
          // handleUserTemplateChange(message.data.payload as IUserTemplateChange);
        }
        if (message.data.event === EVENT_VEHICLE_POSITION) {
          // handleOnVehiclePositionMessageReceived(
          //   message.data.payload as IEventVehiclePositionPayload
          // );
        }
        if (message.data.event === EVENT_VEHICLE_POSITION_BULK) {
          // handleOnVehiclePositionBulkMessageReceived(
          //   message.data.payload as ISocketServerBulkMessage<IEventVehiclePositionPayload>
          // );
        }
        if (message.data.event === EVENT_BLUECOIN_POSITION_BULK) {
          // handleOnBluecoinPositionBulkMessageReceived(
          //   message.data.payload as ISocketServerBulkMessage<EventBluecoinPositionPayload>
          // );
        }
        if (message.data.event === EVENT_EMPTY_VEHICLES) {
          const vehicleIds = await localForageStore.getItem('vehicleIds');
          const data = {
            event: EVENT_USER_VEHICLES,
            payload: vehicleIds,
            userId: UserService.getUserId(),
          };
          SocketConnectionWorker.postMessage(data);
        }
        if (message.data.event === EVENT_EMPTY_GDPR_SETTINGS) {
          const userId = UserService.getUserId();
          const driverId = UserService.getDriverId();
          const data = {
            event: EVENT_GDPR_SETTINGS,
            payload: getGdprMapSettings(gdprSettings, userId, driverId),
            userId,
          };
          SocketConnectionWorker.postMessage(data);
        }
        if (message.data.event === EVENT_EMPTY_USER_DATA) {
          const vehicleIds = await localForageStore.getItem('vehicleIds');
          const selVehicles = await localForageStore.getItem<number[]>('selectedVehicles');
          const selCustomers = await localForageStore.getItem<number[]>('selectedCustomers');
          const userId = UserService.getUserId();
          const driverId = UserService.getDriverId();
          const data = {
            event: EVENT_USER_DATA,
            payload: {
              vehicles: vehicleIds,
              selectedVehicles: selVehicles,
              selectedCustomers: selCustomers,
              gdprSettings: getGdprMapSettings(gdprSettings, userId, driverId),
            },
            userId,
          };
          SocketConnectionWorker.postMessage(data);
        }

        if (message.data.event === EVENT_ECO_DRIVE_VIOLATION) {
          // handleOnEcoDriveViolationMessageRecieve(
          //   message.data.payload as IEventRideEdViolationPayload
          // );
        }
        if (message.data.event === EVENT_ECO_DRIVE_VIOLATION_BULK) {
          // handleOnEcoDriveViolationBulkMessageRecieve(
          //   message.data.payload as ISocketServerBulkMessage<IEventRideEdViolationPayload>
          // );
        }
        if (message.data.event === EVENT_DEFAULT_TEMPLATE_CHANGE) {
          // handleDefaultTemplateChange(
          //   message.data.payload as IUnitDataProcessorDefaultTemplateChange
          // );
        }
        if (message.data.event === EVENT_DEFAULT_TEMPLATE_CHANGE_BULK) {
          // handleDefaultTemplateChangeBulk(
          //   message.data
          //     .payload as ISocketServerBulkMessage<IUnitDataProcessorDefaultTemplateChange>
          // );
        }
        if (message.data.event === EVENT_DATA) {
          // handleNavigatorDataMessageRecieved(
          //   message.data.payload as IUnitDataProcessorNavigatorData
          // );
        }
        if (message.data.event === EVENT_DATA_BULK) {
          // handleOnNavigatorDataBulkMessageRecieved(
          //   message.data.payload as ISocketServerBulkMessage<IUnitDataProcessorNavigatorData>
          // );
        }

        if (message.data.event === EVENT_DATA_CHANGE_BULK) {
          // handleDataChangeBulk(
          //   message.data.payload as ISocketServerBulkMessage<IEventDataChangePayload>
          // );
        }
        // if (message.data.event === EVENT_EXPORT_IN_PROGRESS) {
        //   handleOnExportInProgress(message.data.payload as IMessageExportInProgress);
        // }
      }
    };
  }, [handleOnConnectionOpen, isRidePlayerOpen, gdprSettings]);

  return null;
}
