import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import config from '../../config';
import JWTService from './JWTService';
import showMessage from '../components/Toastr/ToastService';

export interface IHttpServiceOptions {
  showToaster?: boolean; // Show toaster for HTTP status 20x
  showErrorToaster?: boolean; // Show toaster for HTTP status > 40x
  returnAxiosError?: boolean; // Returns the whole Axios error response istead of undefined when error occurs
  returnAxioErrorResponseData?: boolean; // Returns only response data from Axios Error when error occurs
  requestTimeout?: number;
  baseUrl?: string;
  headers?: AxiosRequestHeaders;
  noAuthHeader?: boolean; // Do not send Authorisation header with JWT in request
  returnAxiosResponse?: boolean; // Returns full success Axios response with headers not only data
  queryParams?: Record<string, string>;
}

export const STATUS_NOT_FOUND = 404;
export const STATUS_APP_ERROR = 500;

export const AXIOS_BASE_URL = `${config.Backend.url}/api`;
export const AXIOS_TIMEOUT = 90000;
export const AXIOS_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'Content-Type': 'application/json',
  'Access-Control-Expose-Headers': 'Content-Disposition',
};
const ERR_NETWORK = 'ERR_NETWORK';

const requestConfigSettings = (options: IHttpServiceOptions): AxiosRequestConfig => {
  const requestConfig: AxiosRequestConfig = {
    baseURL: options.baseUrl ? options.baseUrl : AXIOS_BASE_URL,
    timeout: options.requestTimeout ? options.requestTimeout : AXIOS_TIMEOUT,
    headers: options.headers
      ? { ...options.headers, Authorization: JWTService.getToken() }
      : { ...AXIOS_HEADERS, Authorization: JWTService.getToken() },
  };

  if (options.headers && options.headers.Authorization && options.noAuthHeader) {
    delete options.headers.Authorization;
  }

  return requestConfig;
};

const handleSuccessResponseToast = (response: AxiosResponse) => {
  if (response.data.toast) {
    showMessage(
      '',
      response.data.toast.message,
      response.data.toast.type.toLowerCase(),
      response.data.toast.duration,
      response.data.toast.messagePayload || null
    );
  }
  return response;
};

const detailed500Error = (error: any) => {
  if (error.code !== ERR_NETWORK) {
    const additionalMessage =
      config.apiService.detailed500Error && error.response.data && error.response.data.message
        ? error.response.data.message
        : null;

    let errorMessage = 'Unexpected Api Error';
    if (error.response.data && error.response.data.toast) {
      errorMessage = error.response.data.toast.message;
    }
    if (additionalMessage) {
      errorMessage = `Unexpected Api Error ${additionalMessage}`;
    }
    if (error.response.data.toast) {
      showMessage(
        '',
        errorMessage,
        error.response.data.toast.type.toLowerCase(),
        error.response.data.toast.duration,
        error.response.data.toast.messagePayload || null
      );
    } else {
      showMessage('', errorMessage, 'error', null, null);
    }
  }
};

const handleErrorResponseToast = (error: AxiosError) => {
  if (!error.response && error.code !== ERR_NETWORK) {
    // No response from server
    showMessage('', 'Unexpected API Error', 'error');
  }
  // if (error.response && error.response.status === STATUS_NOT_FOUND) {
  //   showMessage('', 'Endpoint not found.', 'error');
  //   return error;
  // }
  console.info('HTTPService error: ', error);
  detailed500Error(error);
  return Promise.reject(error);
  // return error;
};

const createQueryParamsFromObject = (object: Record<string, any>): string => {
  const params = new URLSearchParams();
  Object.keys(object).forEach((queryItem: string) => {
    if (object[queryItem] && !Array.isArray(object[queryItem])) {
      params.append(queryItem, object[queryItem]);
    }
    if (object[queryItem] && Array.isArray(object[queryItem])) {
      console.log('setting: ', queryItem, object[queryItem]);
      object[queryItem].forEach((value: string) => {
        params.append(`${queryItem}[]`, value);
      });
    }
  });
  // console.log('params: ', params.toString());
  return params.toString();
};

export async function get<T>(
  url: string,
  options?: IHttpServiceOptions
): Promise<T | AxiosResponse<unknown, any> | false> {
  let opts: IHttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  const requestConfig: AxiosRequestConfig = requestConfigSettings(opts);

  if (opts.noAuthHeader && requestConfig.headers) {
    delete requestConfig.headers.Authorization;
  }

  const instance = axios.create(requestConfig);

  if (opts.showToaster) {
    instance.interceptors.response.use(handleSuccessResponseToast);
  }

  if (opts.showErrorToaster) {
    instance.interceptors.response.use(undefined, handleErrorResponseToast);
  }

  // instance.interceptors.request.use(
  //   function (response) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return response;
  //   }, function (error) {
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //     return Promise.reject(error);
  //   }
  // );

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return instance
    .get(urlWithParams)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxioErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function post<D, T>(
  url: string,
  data: D,
  options: IHttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: IHttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  const requestConfig: AxiosRequestConfig = requestConfigSettings(opts);

  if (opts.noAuthHeader && requestConfig.headers) {
    delete requestConfig.headers.Authorization;
  }

  const instance = axios.create(requestConfig);

  if (opts.showToaster) {
    instance.interceptors.response.use(handleSuccessResponseToast);
  }

  if (opts.showErrorToaster) {
    instance.interceptors.response.use(undefined, handleErrorResponseToast);
  }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return instance
    .post(urlWithParams, data)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxioErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function put<D, T>(
  url: string,
  data: D,
  options: IHttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: IHttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  const requestConfig: AxiosRequestConfig = requestConfigSettings(opts);

  if (opts.noAuthHeader && requestConfig.headers) {
    delete requestConfig.headers.Authorization;
  }

  const instance = axios.create(requestConfig);

  if (opts.showToaster) {
    instance.interceptors.response.use(handleSuccessResponseToast);
  }

  if (opts.showErrorToaster) {
    instance.interceptors.response.use(undefined, handleErrorResponseToast);
  }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return instance
    .put(urlWithParams, data)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxioErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function patch<D, T>(
  url: string,
  data: D,
  options: IHttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: IHttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  const requestConfig: AxiosRequestConfig = requestConfigSettings(opts);

  if (opts.noAuthHeader && requestConfig.headers) {
    delete requestConfig.headers.Authorization;
  }

  const instance = axios.create(requestConfig);

  if (opts.showToaster) {
    instance.interceptors.response.use(handleSuccessResponseToast);
  }

  if (opts.showErrorToaster) {
    instance.interceptors.response.use(undefined, handleErrorResponseToast);
  }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return instance
    .patch(urlWithParams, data)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxioErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}
