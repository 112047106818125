export const EVENT_CONNECT = 'CONNECT';
export const EVENT_CONNECTION_OPEN = 'CONNECTION_OPEN';
export const EVENT_CONNECTION_CLOSE = 'CONNECTION_CLOSE';
export const EVENT_CONNECTION_ERROR = 'CONNECTION_ERROR';
export const EVENT_JOIN = 'JOIN';
export const EVENT_LOGOUT = 'LOGOUT';
export const EVENT_SELECT_VEHICLES = 'SELECT_VEHICLES';
export const EVENT_GDPR_SETTINGS = 'GDPR_SETTINGS';
export const EVENT_SELECT_CUSTOMERS = 'EVENT_SELECT_CUSTOMERS';
export const EVENT_DISCONNECT = 'DISCONNECT';
export const EVENT_ERROR = 'ERROR';
export const EVENT_LEAVE = 'LEAVE';
export const EVENT_DATA = 'DATA';
export const EVENT_DATA_BULK = 'DATA_BULK';
export const EVENT_DATA_CHANGE = 'DATA_CHANGE';
export const EVENT_DATA_CHANGE_BULK = 'DATA_CHANGE_BULK';
export const EVENT_REPORT_CREATED = 'REPORT_CREATED';
export const EVENT_USER_TEMPLATE_CHANGE = 'USER_TEMPLATE_CHANGE';
export const EVENT_VEHICLE_POSITION = 'VEHICLE_POSITION';
export const EVENT_VEHICLE_POSITION_BULK = 'VEHICLE_POSITION_BULK';
export const EVENT_ONLINE_PANEL = 'ONLINE_PANEL';
export const EVENT_ECO_DRIVE_VIOLATION = 'ECO_DRIVE_VIOLATION';
export const EVENT_ECO_DRIVE_VIOLATION_BULK = 'ECO_DRIVE_VIOLATION_BULK';
export const EVENT_DEFAULT_TEMPLATE_CHANGE = 'DEFAULT_TEMPLATE_CHANGE';
export const EVENT_DEFAULT_TEMPLATE_CHANGE_BULK = 'EVENT_DEFAULT_TEMPLATE_CHANGE_BULK';
export const EVENT_EXPORT_IN_PROGRESS = 'EXPORT_IN_PROGRESS';
export const EVENT_STOP_PROCESSING_MESSAGES = 'STOP_PROCESSING_MESSAGES';
export const EVENT_START_PROCESSING_MESSAGES = 'START_PROCESSING_MESSAGES';
export const EVENT_USER_VEHICLES = 'USER_VEHICLES';
export const EVENT_USER_DATA = 'USER_DATA';
export const EVENT_EMPTY_VEHICLES = 'EMPTY_VEHICLES';
export const EVENT_EMPTY_USER_DATA = 'EMPTY_USER_DATA';
export const EVENT_EMPTY_GDPR_SETTINGS = 'EMPTY_GDPR_SETTINGS';
export const EVENT_SELECTED_VEHICLES = 'SELECTED_VEHICLES';
export const EVENT_SELECTED_CUSTOMERS = 'SELECTED_CUSTOMERS';
export const EVENT_RIDE_CHANGED = 'RIDE_CHANGED';
export const EVENT_BLUECOIN_POSITION_BULK = 'BLUECOIN_POSITION_BULK';
export const EVENT_IS_OPEN = 'EVENT_IS_OPEN';
