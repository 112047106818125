import * as React from 'react';
import { CmdToast, CmdAlert } from '@commander-services/gui-components';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import * as ToastrState from './ToastrState';
import { IToastr } from './ToastrState';
import ConfirmToaster from '../Forms/ConfirmToaster/ConfirmToaster';

interface IToastrProps {
  showAsAltert?: boolean;
  onCloseCallback?: () => void;
}

export default function Toastr(props: IToastrProps): JSX.Element | null {
  const { formatMessage: f, messages } = useIntl();
  const [toastr, setToastr] = useRecoilState<IToastr | null>(ToastrState.toastr);

  const handleClose = () => {
    setToastr(null);
    if (props.onCloseCallback) {
      props.onCloseCallback();
    }
  };

  if (!toastr) {
    return null;
  }

  // const translatedTitle = toastr.title
  //   ? f({ id: toastr.title })
  //   : f({ id: `toastr.${toastr.color}` });
  // const translatedMessage = toastr.messagePayload
  //   ? f({ id: toastr.message, defaultMessage: messages[toastr.message] }, toastr.messagePayload)
  //   : f({ id: toastr.message, defaultMessage: messages[toastr.message] });

  // return (
  //   <XyzTransition xyz="fade rotate-right ease-out-back">
  //     {shown && (
  //       <div
  //         className="square"
  //         style={{ width: '100px', height: '100px', backgroundColor: 'red' }}
  //       />
  //     )}
  //   </XyzTransition>
  // );

  if (props.showAsAltert && toastr.title) {
    const alertData = {
      title: f({ id: toastr.title }),
      message: toastr.messagePayload
        ? f({ id: toastr.message, defaultMessage: messages[toastr.message] }, toastr.messagePayload)
        : f({ id: toastr.message, defaultMessage: messages[toastr.message] }),
      messagePayload: toastr.messagePayload,
      type: 'warning',
    };
    return <CmdAlert id="login-alert" data={alertData} onCloseCallback={handleClose} />;
  }

  let toastMessage = toastr.messagePayload
    ? f({ id: toastr.message, defaultMessage: messages[toastr.message] }, toastr.messagePayload)
    : f({ id: toastr.message, defaultMessage: messages[toastr.message] });

  if (toastr.messagePayload && toastr.messagePayload.code === '-') {
    toastMessage = f({
      id: 'unexpectedErrorWithoutCode',
      defaultMessage: messages.unexpectedErrorWithoutCode,
    });
  }

  return (
    <ConfirmToaster
      id="toaster"
      title={toastr.title ? f({ id: toastr.title }) : f({ id: `toastr.${toastr.color}` })}
      message={toastMessage}
      type={toastr.color as 'success' | 'info' | 'error' | 'warning'}
      duration={toastr.duration}
      onClose={handleClose}
    />
  );
}
