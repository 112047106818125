export const ASCENDING_KEY = 'asc';
export const DESCENDING_KEY = 'desc';

export const TIMESTAMP_KEY = 'timestamp';
export const LOCKED_KEY = 'locked';
export const UNLOCKED_KEY = 'unlocked';
export const RESTRICTED_KEY = 'restricted';
export const VEHICLE_KEY = 'vehicle';
export const PRIVATE_KEY = 'isPrivate';
export const ADDRESS_KEY = 'address';
export const INFO_KEY = 'info';
export const DISTANCE_KEY = 'distance';
export const DURATION_KEY = 'duration';
export const ECO_DRIVE_KEY = 'ecoScore';
export const AVARAGE_SPEED_KEY = 'avgSpeed';
export const AVARAGE_CONSUMPTION_KEY = 'avgConsumption';
export const MAXIMAL_SPEED_KEY = 'maxSpeed';
export const DRIVER_KEY = 'driver';
export const FUEL_CONSUMPTION_CAN_KEY = 'fuelConsumption';
export const CUSTOMER_KEY = 'customer';
export const ACTIONS_KEY = 'actions';
export const URL_RIDE_MAP = 'ride-map';
export const MILEAGE_END_KEY = 'mileage';
export const INCREASE_KEY = 'increase';
export const DECLINE_KEY = 'decline';
export const SOURCE_KEY = 'source';
export const INFO_FOR_PARKING_KEY = 'parking';
export const NONE_KEY = 'none';

export const NEW_RIDE_ACTION = 'action_new_ride';
export const RIDE_PURPOSE_ACTION = 'action_ride_add_purpose';
export const RIDE_COMMENT_ACTION = 'action_ride_edit_comment';
export const CHANGE_DRIVER_ACTION = 'action_change_driver';
export const TYPE_PRIVATE_ACTION = 'action_change_type_private';
export const TYPE_PUBLIC_ACTION = 'action_change_type_business';
export const SHOW_RIDE_ACTION = 'action_display_ride';
export const PARKING_PURPOSE_ACTION = 'action_parking_add_purpose';
export const PARKING_COMMENT_ACTION = 'action_parking_edit_comment';

export const TOTAL_KEY = 'total';
export const PUBLIC_KEY = 'public';
export const PRIVATE_SECTION_KEY = 'private';

export const WAYPOINT_ID = 'waypoint';
export const MIDNIGHT_ID = 'midnight';
export const EVENT_ID = 'event';
export const ALARM_ID = 'alarm';

export const START_TYPE = 'start';
export const END_TYPE = 'end';
export const PARKING_TYPE = 'parking';

export const RIDE_COUNT = 'rideCount';
export const RIDE_DURATION = 'rideDuration';
export const PARKING_DURATION = 'parkingDuration';
export const FUEL_CONSUPTION = 'fuelConsumption';
export const DISTANCE = 'distance';

export const IS_MANUAL_RIDE = 'manual';
export const IS_GPS_RIDE = 'gen';

export const IS_COMMENT = 'comment';
export const IS_TAG = 'tag';

export const SUFFIX_SPEED = 'km/h';

export const PARKING_KEY = 'parking';
export const COMMENT_KEY = 'comment';
export const WAYPOINT_KEY = 'waypoint';
export const ALARM_KEY = 'alarm';
export const TAG_KEY = 'tag';
export const EVENT_KEY = 'event';

export const DAILY_KEY = 'daily';
export const WEEKLY_KEY = 'weekly';
export const MONTHLY_KEY = 'monthly';
export const MIN_OFFSET_LENGTH = 50;

export const ESCAPE_KEY = 'Escape';

export const INVALID_DATE = 'Invalid Date';
export const ID_RESET = 'reset';
export const OVERVIEW_KEY = 'overview';

export const RANGE_SELECT_CLASSNAME = [
  'form-control input active',
  'input-time',
  'dropdown-footer',
  'CmdCheckbox',
  'form-control input active',
  'arrowUp',
  'arrowDown',
  'icons-module_cmd-icon',
  'input-time',
  'CmdCalendar',
  'form-control',
  'timepicker',
  'p-0',
  'js-checkbox-indeterminate',
  'CmdRangeFilterForm',
  'CmdInput',
  'numInput flatpickr-minute',
  'numInput flatpickr-hour',
  'rs-calendar-time-dropdown-cell',
  'rs-btn',
];

export const IS_USAGE = 'usage';
export const IS_USAGE_PARKING = 'usage-parking';
